import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithPrepareHeaders } from "./util";

const BASE_URL = '/api/profile/tenant'

const baseQuery = fetchBaseQueryWithPrepareHeaders({
  baseUrl: `${BASE_URL}/`,
})

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery,
  tagTypes: ['Tenant'],
  endpoints: (builder:any) => ({
    getTenant: builder.query({
      query: () => ``,
      providesTags: ['Tenant']
    }),
    updateTenant: builder.mutation({
      query: ({item}) => ({
        url: item.ident,
        method: 'PUT',
        body: item
      }),
      invalidatesTags: ['Tenant']
      
    })
  })
})

export const { 
  useGetTenantQuery,
  useUpdateTenantMutation,
} = tenantApi