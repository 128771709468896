//import axios from "axios";
import { Buffer } from 'buffer'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//import useSearchParams from "../util/use-search-params";

//import loadInitialPrivileges from '../config/load-initial-privileges.json'
import { useCheckHasPrivilegesMutation, useGetOidcConfigQuery, useRefreshTokenMutation, useRetrieveTokenMutation } from "../api/auth-api";
import { useAppDispatch } from '../store';
import { setUserToken } from '../user-slice';

//const loadInitialPrivileges = {}

const url = new URL(window.document.location.href)
const CLIENT_ROOT = `${url.protocol}//${url.hostname}${url.port.length ? `:${url.port}` : ''}`

const UserContext = createContext<any>(null)

export const useUserContext = () => useContext(UserContext)

export default function OIDC({ children }) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { code, state: stateBase64, error } = useMemo<any>(() => ({
    code: searchParams.get('code'),
    state: searchParams.get('state'),
    error: searchParams.get('error'),
  }), [searchParams]) //{ code: '1234', state: 'foo', error: null } //useSearchParams()
  const [user, setUser] = useState<any>(null)
  //const axiosInterceptorRef = useRef()
  const [privileges, setPrivileges] = useState<any>(null)
  const privilegesRef = useRef<any>()
  const refreshTokenTimeoutRef = useRef<any>()
  const [retrieveTokenApiCall] = useRetrieveTokenMutation()
  const [refreshTokenApiCall] = useRefreshTokenMutation()
  const [checkHasPrivilegesApiCall] = useCheckHasPrivilegesMutation()

  const signinRedirectUri = useMemo(() => `${CLIENT_ROOT}/signin-redirect`, [])

  privilegesRef.current = privileges

  const setUserAndToken = useCallback(user => {
    if(user) {
      console.log("Setting user token", user)
      dispatch(setUserToken({ userToken: user.accessToken }))
    }
    setUser(user)
  }, [setUser, dispatch/*setAxiosToken*/])

  const existingUser = useMemo(() => {
    let result = null
    const existingUserJson = sessionStorage.getItem("oidcUser")
    if(existingUserJson) {
      const existingUser = JSON.parse(existingUserJson)
      if(existingUser) {
        existingUser.expiresAt = new Date(existingUser.expiresAt)
      }
      //console.log("Original existing user", existingUser)
      result = existingUser?.expiresAt?.valueOf() - 60 * 1000 > new Date().valueOf() ? existingUser : null
    }
    return result
  }, [])


  const clearUser = useCallback(() => {
    console.log("Clearing user")
    sessionStorage.removeItem("oidcUser")
    setUser(null)
  }, [setUser])

  const { data: authorityData }: { data: any } = useGetOidcConfigQuery({}) as { data: any }

  useEffect(() => {
    if(!authorityData) {
      return
    }

    if(user) {
      console.log("Existing user")
    } else if(existingUser) {
      const user = existingUser
      setUserAndToken(user)
    } else if(location.pathname === '/signin-redirect') {
      (async () => {
        if(!error) {
          let token
          try {
            const { data } = (await retrieveTokenApiCall({ code, redirectUri: signinRedirectUri})) as { data }
            token = data
            //token = (await axios.post(`/api/shark3d/auth/v1/auth/user/retrieve-token`, { code, redirectUri: signinRedirectUri })).data
          } catch(e) {
            navigate('/', { replace: true })
          }
          const newUser = {
            id: token.userId,
            username: token.userName,
            usernameDisplay: token.userNameDisplay,
            email: token.userEmail,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
            idToken: token.idToken,
            expiresAt: new Date(token.validUntil + 'Z')
          }
          sessionStorage.setItem("oidcUser", JSON.stringify(newUser))
          setUserAndToken(newUser)
        }
      })()
    } else {
      (async () => {
        const stateObj = { path: `${location.pathname}${location.search || ''}` }
        const b = Buffer.from(JSON.stringify(stateObj))
        const stateStr = b.toString('base64')
        const authorizationEndpoint = authorityData.authorizationEndpoint
        const clientId = authorityData.clientId
        const additionalAuthorizationUrlParameters = authorityData.additionalAuthorizationUrlParameters
        const authorizationEndpointURL = new URL(authorizationEndpoint)
        const additionalParametersURL = new URL(additionalAuthorizationUrlParameters, authorizationEndpointURL)
        const protocol = authorizationEndpointURL.protocol
        const host = authorizationEndpointURL.host
        const pathname = authorizationEndpointURL.pathname
        const parameters = {}
        additionalParametersURL.searchParams.forEach((v, k) => parameters[k] = v)
        authorizationEndpointURL.searchParams.forEach((v, k) => parameters[k] = v)
        parameters['client_id'] = clientId
        parameters['state'] = stateStr
        parameters['scope'] = authorityData.scopes
        parameters['redirect_uri'] = signinRedirectUri
        parameters['response_type'] = 'code'
        
        const url = `${protocol}//${host}${pathname}?${Object.keys(parameters).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(parameters[k])}`).join("&")}`
        console.log(authorizationEndpointURL, additionalParametersURL, url,  parameters)
        window.document.location.href = url
      })()
    }
  }, [authorityData, signinRedirectUri, location, code, error, stateBase64, user, setUserAndToken, existingUser, retrieveTokenApiCall, navigate])


  useEffect(() => {
    //console.log("User", user, "location", location.pathname)
    if(user && location.pathname === '/signin-redirect') {
      const b = Buffer.from(stateBase64, 'base64')
      const state = JSON.parse(b.toString())
      console.log("Redirecting to state", state)
      navigate(state.path, { replace: true })
    }
  }, [user, location.pathname, navigate, stateBase64])

  useEffect(() => {
    if(!user) {
      return
    }
    (async () => {
      let privileges
      try {
        const { data } = (await checkHasPrivilegesApiCall({ privileges: ['acl:allowed'] })) as { data } 
        privileges = data
        //privileges = (await axios.post(`/api/shark3d/auth/v1/auth/user/has-privileges`, loadInitialPrivileges)).data
      } catch(e) {
        console.error("Could not check privileges")
        clearUser()
        return
      }
      if(!privileges || !privileges["acl:allowed"]) {
        console.error("User has no login privilege")
        clearUser()
        return
      }
      setPrivileges(privileges)
    })()
  }, [setPrivileges, user, clearUser, checkHasPrivilegesApiCall])

  const refreshToken = useCallback(async () => {
    console.log("Refresh user token")
    clearTimeout(refreshTokenTimeoutRef.current)
    let token
    try {
      const { data } = (await refreshTokenApiCall({ refreshToken: user.refreshToken })) as { data }
      token = data
      if(!token) {
        window.location.href = "/"
      }
      //token = (await axios.post(`/api/shark3d/auth/v1/auth/user/refresh-token`, { refreshToken: user.refreshToken })).data
    } catch(e) {
      window.location.href = "/"
      return
    }
    const newUser = {
      id: token.userId || user.id,
      username: token.userName || user.userName,
      usernameDisplay: token.userNameDisplay || user.userNameDisplay,
      email: token.userEmail || user.userEmail,
      accessToken: token.accessToken,
      refreshToken: token.refreshToken || user.refreshToken,
      idToken: token.idToken || user.idToken,
      expiresAt: new Date(token.validUntil + 'Z')
    }
    sessionStorage.setItem("oidcUser", JSON.stringify(newUser))
    setUserAndToken(newUser)
  }, [user, setUserAndToken, refreshTokenTimeoutRef, refreshTokenApiCall])

  useEffect(() => {
    if(!user) {
      return
    }
    const timeoutDelay = (user.expiresAt.valueOf() - new Date().valueOf()) / 2
    console.log("Setting timeout", timeoutDelay, user.expiresAt, new Date())
    refreshTokenTimeoutRef.current = setTimeout(refreshToken, timeoutDelay)
  }, [user, refreshToken, refreshTokenTimeoutRef])

  /*
  const hasPrivilege = useCallback(async (privilege) => {
    if(privileges[privilege] !== undefined && !(privileges[privilege].then)) {
      return privileges[privilege]
    }
    let hasPrivilege
    try {
      let hasPrivilegePromise = privileges[privilege]
      if(!hasPrivilegePromise) {
        //hasPrivilegePromise = axios.post(`/api/shark3d/auth/v1/auth/user/has-privileges`, [privilege])
        const newPrivileges = {...privileges, [privilege]: hasPrivilegePromise}
        setPrivileges(newPrivileges)
      }
      hasPrivilege = (await hasPrivilegePromise).data
    } catch(e) {
      console.error("Could not check privileges")
      clearUser()
      return false
    }
    const newPrivileges = {...privileges, ...hasPrivilege}
    //for(let key in newPrivileges) newPrivileges[key] = true;
    setPrivileges(newPrivileges)
    return hasPrivilege[privilege]
  }, [privileges, setPrivileges, clearUser])
  */

  const hasPrivileges = useCallback(async (requestedPrivileges) => {
    let privileges = privilegesRef.current

    let result = {}
    const missingPrivileges = {}

    let resolve, reject
    const newPromise = new Promise((r1, r2) => { resolve = r1; reject = r2; })
    const newPrivileges = {...privilegesRef.current}

    let needsPrivilegeUpdate = false
    //let hasMissingPrivileges = false
    for(let requestedPrivilege of requestedPrivileges) {
      if(privileges[requestedPrivilege] !== undefined && !(privileges[requestedPrivilege].then)) {
        result[requestedPrivilege] = privileges[requestedPrivilege]
      } else {
        //hasMissingPrivileges = true
        let hasPrivilegePromise = privileges[requestedPrivilege]
        if(!hasPrivilegePromise) {
          hasPrivilegePromise = newPromise
          newPrivileges[requestedPrivilege] = newPromise
          needsPrivilegeUpdate = true
        }
        missingPrivileges[requestedPrivilege] = hasPrivilegePromise
      }
    }

    //console.log("checking privilege", requestedPrivileges, privileges, result, needsPrivilegeUpdate)

    if(!needsPrivilegeUpdate) {
      return result
    }

    setPrivileges(newPrivileges)

    checkHasPrivilegesApiCall({ privileges: Object.keys(missingPrivileges) }).then(r => resolve(r)).catch(e => reject(e))
    //axios.post(`/api/shark3d/auth/v1/auth/user/has-privileges`, Object.keys(missingPrivileges)).then(r => resolve(r)).catch(e => reject(e))

    let results
    try {
      results = (await Promise.all(Object.values(missingPrivileges))).map((r:any) => r.data)
      console.log("Received privileges", results)
    } catch(e) {
      console.error("Could not check privileges")
      clearUser()
      return result
    }

    results.forEach(r => result = ({...result, ...r}))

    setPrivileges({...privilegesRef.current, ...result})
    
    return result

  }, [privilegesRef, setPrivileges, clearUser, checkHasPrivilegesApiCall])

  const logout = useCallback(() => {
    (async () => {
      //await axios.get(authorityData.end_session_endpoint)
      sessionStorage.removeItem("oidcUser")
      //setPrivileges(null)
      //setUser(null)
      setTimeout(() => {
        window.location.href = `${authorityData?.endSessionEndpoint}?id_token_hint=${user?.idToken}&post_logout_redirect_uri=${CLIENT_ROOT}`
      }, 0)
    })()
  }, [authorityData, user])

  return (
    <>
    {
      privileges ? <UserContext.Provider value={{ user, logout, /* hasPrivilege, */ hasPrivileges, refreshToken }}>{ children }</UserContext.Provider> : (error ? <div>Error logging in: {error}</div> : <div>Checking Authentication &hellip;</div>)
    }
    </>
  )
}
