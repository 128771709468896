import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithPrepareHeaders } from "./util";

const BASE_URL = '/api/auth'

const baseQuery = fetchBaseQueryWithPrepareHeaders({
  baseUrl: `${BASE_URL}/`,
})

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  tagTypes: ['Auth', 'OIDC-Config'],
  endpoints: (builder:any) => ({
    getOidcConfig: builder.query({
      query: () => `auth/oidc-config`,
      providesTags: ['OIDC-Config']
    }),
    retrieveToken: builder.mutation({
      query: ({ code, redirectUri }) => ({
        url: 'auth/user/retrieve-token',
        method: 'POST',
        body: {
          code,
          redirectUri
        }
      }),
    }),
    refreshToken: builder.mutation({
      query: ({ refreshToken }) => ({
        url: 'auth/user/refresh-token',
        method: 'POST',
        body: {
          refreshToken
        }
      }),
    }),
    checkHasPrivileges: builder.mutation({
      query: ({ privileges }) => ({
        url: 'auth/user/has-privileges',
        method: 'POST',
        body: privileges
      })
    })
  })
})

export const { 
  useGetOidcConfigQuery,
  useRetrieveTokenMutation,
  useRefreshTokenMutation,
  useCheckHasPrivilegesMutation,
} = authApi