import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import './App.css';
import { UserOutlined, PlusCircleOutlined, PicRightOutlined, DeleteOutlined, BankOutlined, SwapOutlined, LoadingOutlined, LogoutOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Layout, Menu, theme } from 'antd';
import Profile from './components/pages/profile';
import { Provider } from 'react-redux';
import { store, useAppDispatch, useAppSelector } from './store';
import { useGetConsultantsQuery } from './api/consultant-api';
import { Consultant } from './types/consultant';
import NewVariantForm from './components/new-variant-form';
import { useDeleteProfileByIdentMutation, useGetProfilesByConsultantIdentsLightQuery } from './api/profile-api';
import { ProfileType } from './types/profile';
import { createBrowserRouter, RouterProvider, Outlet, useNavigate } from 'react-router-dom';
import CompareProfileVariants from './components/pages/compare-profile-variants';
import Company from './components/pages/company';
import SaveReminder from './components/save-reminder';
import { setExpandedMenuItems, setSiderExpanded } from './ui-state-slice';
import { styled } from 'styled-components';
import Breadcrumb, { BreadcrumbWrapper } from './components/breadcrumb';
import FeedbackButton from './components/feedback/feedback-button';
import OIDC, { useUserContext } from './components/oidc';
import LandingPage from './components/pages/landing-page';
import logo from './logo1.svg'

const { Header, Content, Sider } = Layout

const useExpandedMenuItems = ():string[] => {
  return useAppSelector((state) => state.uiState.expandedMenuItems) || []
}

const useSetExpandedMenuItems = (): ((expanded:string[]) => void) => {
  const dispatch = useAppDispatch()
  return (expanded:string[]) => { 
    dispatch(setExpandedMenuItems({ expanded }))
  }
}

const useExpandedMenuItemsReduxState = (): [string[], (expanded:string[]) => void] => {
  return [useExpandedMenuItems(), useSetExpandedMenuItems()]
}

const useSiderExpanded = ():boolean => {
  return useAppSelector((state) => state.uiState.siderExpanded === undefined ? true : state.uiState.siderExpanded)
}

const useSetSiderExpanded = (): ((expanded:boolean) => void) => {
  const dispatch = useAppDispatch()
  return (expanded:boolean) => {
    dispatch(setSiderExpanded({ expanded }))
  }
}

const useSiderExpandedReduxState = (): [boolean, (expanded:boolean) => void] => {
  return [useSiderExpanded(), useSetSiderExpanded()]
}

const MenuStyleWrapper = styled.div`
  overflow: auto;
  height: 100%;
`

const SideMenu: React.FC = () => {
  const [createVariant, setCreateVariant] = useState(null)
  const [expandedMenuItems, setExpandedMenuItems] = useExpandedMenuItemsReduxState()
  const siderExpanded = useSiderExpanded()
  const [internalExpandedMenuItems, setInternalExpandedMenuItems] = useState<string[]>([])
  const { data: consultants}: {data?: Consultant[]} = useGetConsultantsQuery({}) as {data?: Consultant[]}
  const [callDeleteProfile] = useDeleteProfileByIdentMutation()
  const navigate = useNavigate()
  const [deletingVariants, setDeletingVariants] = useState<string[]>([])
  const deletingVariantsRef = useRef<string[]>([])
  const [deletedVariants, setDeletedVariants] = useState<string[]>([])
  const deletedVariantsRef = useRef<string[]>([])
  deletingVariantsRef.current = deletingVariants
  deletedVariantsRef.current = deletedVariants

  const { data: profilesByConsultantIdents }: { data?:ProfileType[] } = useGetProfilesByConsultantIdentsLightQuery(consultants?.map(c => c.ident), { skip: !consultants }) as { data?: ProfileType[] }

  const profilesByConsultant = useMemo(() => {
    const newProfilesByConsultant = {}
    profilesByConsultantIdents?.forEach((p:ProfileType) => {
      const consultantIdent:string = p.consultant?.ident || ''
      if(!newProfilesByConsultant[consultantIdent]) {
        newProfilesByConsultant[consultantIdent] = []
      }
      newProfilesByConsultant[consultantIdent].push(p)
    })
    return newProfilesByConsultant
  }, [profilesByConsultantIdents])

  const deleteVariant = useCallback(async (variantId:string) => {
    setDeletingVariants([...deletingVariantsRef.current, variantId])
    await callDeleteProfile({ id: variantId })
    navigate(`/profile/${variantId.replace(/-\d+$/, '-0')}`)
    setDeletingVariants([...deletingVariantsRef.current.filter(v => v !== variantId)])
    setDeletedVariants([...deletedVariantsRef.current, variantId])
  }, [callDeleteProfile, navigate, setDeletingVariants, deletingVariantsRef, setDeletedVariants, deletedVariantsRef])

  useEffect(() => {
    const newDeletedVariants = deletedVariantsRef.current.filter(id => !profilesByConsultantIdents?.find(p => `${p.consultant?.ident}-${p.variant}` === id))
    setDeletedVariants(newDeletedVariants)
  }, [profilesByConsultantIdents, setDeletedVariants, deletedVariantsRef])

  //console.log("Deleting variants", deletingVariants)

  const isVariantDeleted = useCallback((profile:ProfileType) => {
    const ident = `${profile.consultant?.ident}-${profile.variant}`
    return deletingVariants.indexOf(ident) !== -1 || deletedVariants.indexOf(ident) !== -1
  }, [deletedVariants, deletingVariants])

  const consultantsMenuItems = useMemo(() => consultants?.map((c:Consultant) => ({
    key: c.ident,
    icon: <UserOutlined />,
    label: `Profil ${c.firstName} ${c.lastName}`,
    children: [
      ...(profilesByConsultant[c.ident]?.map((p:ProfileType, idx:number) => ({
        icon: <PicRightOutlined />,
        label: idx === 0 ? "Hauptprofil" : <>{ p.description } <Button icon={isVariantDeleted(p) ? <LoadingOutlined /> : <DeleteOutlined/>} onClick={() => deleteVariant(`${p.consultant?.ident}-${p.variant}`)} type="text" /></>,
        key: `variant-${p.consultant?.ident}-${p.variant}`,
        disabled: isVariantDeleted(p),
      })) || [{
        icon: <LoadingOutlined />,
        label: <>Varianten werden geladen &hellip;</>,
        key: `loading-variants`
      }]),
      {
        icon: <PlusCircleOutlined />,
        label: "Neue Profilvariante",
        key: `create-variant-${c.ident}`
      },
      {
        icon: <SwapOutlined />,
        label: "Varianten abgleichen",
        key: `compare-variants-${c.ident}`
      },
    ]
  })), [consultants, profilesByConsultant, deleteVariant, isVariantDeleted])

  const menuItems = useMemo(() => ([
    {
      key: 'company',
      icon: <BankOutlined />,
      label: 'Firmendaten'
    },
    ...(consultantsMenuItems || []),
  ]), [consultantsMenuItems])

  const onClick = useCallback((item:any) => {
    if(item.key.startsWith('create-variant-')) {
      setCreateVariant(item.keyPath[1])
    }
  }, [setCreateVariant])

  const onSelectMenuItem = useCallback((item:any) => {
    if(item.key === 'company') {
      navigate('/company')
    } else if(item.key.startsWith('variant-')) {
      navigate(`/profile/${item.key.substring('variant-'.length)}`)
    } else if(item.key.startsWith('compare-variants-')) {
      navigate(`/consultant/${item.keyPath[1]}/compare-profile-variants`)
    }
  }, [navigate])

  const setEffectiveExpandedMenuItems = useCallback((expanded:string[]) => {
    setInternalExpandedMenuItems(expanded)
    if(siderExpanded) {
      setExpandedMenuItems(expanded)
    }
  }, [setExpandedMenuItems, setInternalExpandedMenuItems, siderExpanded])

  const effectiveExpandedMenuItems = useMemo(() => {
    return siderExpanded ? expandedMenuItems : internalExpandedMenuItems
  }, [siderExpanded, expandedMenuItems, internalExpandedMenuItems])

  const onCompleteCreateVariant = useCallback((ident:string | null) => {
    setCreateVariant(null)
    if(ident) {
      navigate(`/profile/${ident}`)
    }
  }, [navigate])

  return (
    <>
      <NewVariantForm consultantIdent={createVariant} onComplete={onCompleteCreateVariant} />
      <MenuStyleWrapper>
        <Menu
          mode="inline"
          openKeys={effectiveExpandedMenuItems || consultants?.map((c:Consultant) => c.ident)}
          onOpenChange={(menuItems) => setEffectiveExpandedMenuItems(menuItems) }
          style={{ height: '100%', borderRight: 0}}
          items={menuItems}
          onClick={onClick}
          onSelect={onSelectMenuItem}
          />
      </MenuStyleWrapper>
    </>
  )
}

const TopMenu = () => {
  const { user, logout } = useUserContext()

  const onClick = useCallback((item:any) => {
    if(item.key === "logout" && logout) {
      logout()
    }
  }, [logout])

  return (
    <Menu
      theme="light"
      mode="horizontal"
      defaultSelectedKeys={['2']}
      onClick={onClick}
      items={[
        {
          key: "user",
          icon: <UserOutlined />,
          label: user?.usernameDisplay,
          children: [
            {
              key: "logout",
              icon: <LogoutOutlined />,
              label: "Abmelden"
            }
          ]
        }
      ]}
    />
  )
}

const Root: React.FC = () => {
  const [siderExpanded, setSiderExpanded] = useSiderExpandedReduxState()

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <OIDC>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,

          token: {
            colorPrimary: '#2D5DF5',
            
            // Seed Token
            //colorPrimary: '#00b96b',
            //borderRadius: 2,

            // Alias Token
            //colorBgContainer: '#f6ffed',
          },
        }}
      >
        <Layout style={{ height: "100vh", overflow: "hidden" }}>
          <Header style={{ display: 'flex', backgroundColor: 'white' }}>
            <img src={logo} alt="" style={{ height: "3em", marginTop: "1em" }} />
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: "row-reverse" }}>
              <TopMenu />
            </div>
          </Header>
          <Layout>
            <Sider collapsible collapsed={!siderExpanded} onCollapse={collapsed => setSiderExpanded(!collapsed)} width={300} style={{ background: colorBgContainer }}>
              <SideMenu />
            </Sider>
            <Layout style={{ padding: 0, paddingLeft: '24px', paddingRight: '24px' }}>
              <BreadcrumbWrapper>
                <>
                  <Breadcrumb />
                  <Content
                    style={{
                      padding: 24,
                      paddingBottom: 0,
                      margin: 0,
                      minHeight: 280,
                      background: colorBgContainer
                    }}>
                      <Outlet />
                    </Content>
                  </>
                </BreadcrumbWrapper>
            </Layout>
          </Layout>
        </Layout>
      </ConfigProvider>
    </OIDC>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: 'signin-redirect'
      },
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: "company",
        element: <Company />,
      },
      {
        path: "profile/:profileId",
        element: <Profile />,
      },
      {
        path: "consultant/:consultantId/compare-profile-variants",
        element: <CompareProfileVariants />
      }
    ]
  }
]);

const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <FeedbackButton />
        <SaveReminder>
          <RouterProvider router={router} />
        </SaveReminder>
      </Provider>
    </>
  );
}

export default App;
