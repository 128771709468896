import { useState, useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { pdfjs, Document, Page } from 'react-pdf';
import { useRenderProfileMutationMutation } from '../api/profile-api';
import { Pagination, Dropdown, MenuProps } from 'antd';
import 'react-pdf/dist/Page/TextLayer.css';
import { DownOutlined } from '@ant-design/icons';
import { ProfileType } from '../types/profile';
import LoadingOverlay from './loading-overlay';

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"

const PdfPreview = forwardRef(({ getData }:any, ref) => {
  const [previewUrlStack, setPreviewUrlStack] = useState<string[]>([])
  const [activePreviewUrl, setActivePreviewUrl] = useState<string>()
  const previewUrlStackRef = useRef<string[]>()
  previewUrlStackRef.current = previewUrlStack
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadType, setDownloadType] = useState('docx')

  //const renderProfileTimeoutRef = useRef<any>()
  const [renderProfile, { isLoading: rendering, isError }] = useRenderProfileMutationMutation()

  const filterData = useCallback(data => {
    return data ? {
      ...data, 
      keywords: data.keywords?.map((k:string) => k.trim()).filter((k:string) => k.length > 0) || [],
      skillGroups: data.skillGroups?.filter(s => s && s.active).map(sg => ({...sg, skills: sg.skills?.filter(s => s && s.active) || []})) || [],
      shortProfileItems: data.shortProfileItems?.filter(s => s && s.active) || [],
      educationItems: data.educationItems?.filter(s => s && s.active) || [],
      projects: data.projects?.filter(s => s && s.active).map(p => ({...p, skills: (p.skills?.filter(s => s && s.active) || [])})) || [],
      testimonialItems: data.testimonialItems?.filter(s => s && s.active) || [],
    } : null
  }, [])

  const updatePreview = useCallback(async (data) => {
    if(data) {
      const filteredData = filterData(data)
      const response:any = await renderProfile({ item: filteredData, format: 'pdf' })
      setPreviewUrlStack([response.data, ...(previewUrlStackRef.current || [])])
    }
  }, [renderProfile, setPreviewUrlStack, previewUrlStackRef, filterData])

  useImperativeHandle(ref, () => ({
    updatePreview
  }))

  const onDocumentLoadSuccess = useCallback((previewUrl:string, numPages: number) => {
    setNumPages(numPages)
    if(currentPage > numPages) {
      setCurrentPage(numPages)
    }
    setActivePreviewUrl(previewUrl)
    setTimeout(() => {
      const oldStack:string[] = previewUrlStackRef.current || []
      setPreviewUrlStack([previewUrl])
      oldStack.filter(s => s !== previewUrl).forEach(s => URL.revokeObjectURL(s))
    }, 1000)
  }, [previewUrlStackRef, setPreviewUrlStack, setNumPages, setActivePreviewUrl, currentPage, setCurrentPage])

  const items: MenuProps['items'] = useMemo(() => [
    {
      label: 'DOCX',
      key: 'docx',
      onClick: () => setDownloadType('docx'),
    },
    {
      label: 'PDF',
      key: 'pdf',
      onClick: () => setDownloadType('pdf'),
    },
  ], [])

  const downloadDocument = useCallback(async (data:ProfileType, downloadType) => {
    //console.log("Downloading", evt)
    const filteredData = filterData(data)
    const response:any = await renderProfile({ item: filteredData, format: downloadType })
    const a = document.createElement("a")
    a.setAttribute("style", "display: none")
    a.href = response.data
    a.download = `Profil ${data.consultant?.firstName} ${data.consultant?.lastName} ${data.description}.${downloadType}`
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(a.href)
  }, [filterData, renderProfile])

  return (
    <div style={{ overflow: "auto", paddingRight: "1em" }}>
      <div style={{ marginBottom: '1em', width: '100%', display: "flex"}}>
        <div style={{ flex: 1 }} />
         <Pagination defaultCurrent={1} current={currentPage} total={numPages} onChange={setCurrentPage} pageSize={1} />
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ width: "597px", height: "843px", position: "relative" }}>
        { isError && <div>Vorschau konnte nicht geladen werden</div> }
        { !isError && previewUrlStack.map((previewUrl:string) => (
          <Document key={previewUrl} file={previewUrl} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(previewUrl, numPages)} onLoadError={e => console.error("Error loading pdf", e)} >
            <div style={{ border: "1px solid gray", position: "absolute", top: 0, left: 0, opacity: previewUrl === activePreviewUrl ? '1.0' : '0', transition: 'opacity 1s' }}>
              <Page pageNumber={currentPage} renderTextLayer={false} renderAnnotationLayer={false} />
            </div>
          </Document>
        )) }
        <LoadingOverlay isLoading={rendering} loadingLabel={<>Vorschau wird aktualisiert &hellip;</>}/>
      </div>
      <div style={{ marginTop: '1em', width: '100%', display: "flex"}}>
        <div style={{ flex: 1 }} />
        <Pagination defaultCurrent={1} current={currentPage} total={numPages} onChange={setCurrentPage} pageSize={1} />
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ marginTop: '1em', width: '100%', display: "flex"}}>
        <div style={{ flex: 1 }} />
        <div>
          <Dropdown.Button
            icon={<DownOutlined />}
            loading={false}
            menu={{ items }}
            onClick={() => downloadDocument(getData(), downloadType)}
          >
            { downloadType.toLocaleUpperCase() }
          </Dropdown.Button>
        </div>
      </div>
    </div>
  )
})

export default PdfPreview