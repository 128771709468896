import { ReactNode } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined, CheckSquareOutlined, CloseOutlined } from "@ant-design/icons";
import * as Diff from 'diff'
import { styled } from 'styled-components'
import { ProfileType, Project } from "../types/profile";
import ImageTransformContainer from '../components/image-transform-container';

export interface ChangeType {
  key: string[],
  uniqueKey?: string,
  sortKey?:string,
  changed: boolean,
  description: ReactNode,
  textA: ReactNode,
  textB: ReactNode,
  iconA: ReactNode,
  actionA: () => ProfileType,
  descriptionActionA: ReactNode,
  iconB: ReactNode,
  actionB: () => ProfileType,
  descriptionActionB: ReactNode,
}

const AddedSpan = styled.span`
  background-color: #AAFFAA;
`

const convertBreaks = (text:string): ReactNode => {
  const fragments = text.split("\n")
  return (<>
    {fragments.map((f, idx) => <>{f}{ idx < fragments.length - 1 ? <br/> : undefined }</>)}
  </>)
}

const renderTextDiffs = (textA:string, textB:string) => {
  const changes = Diff.diffWords(textA, textB)

  //console.log("Changes", changes)

  return { diffA: (<div>
      {
        changes.filter(c => !c.added).map(c => c.removed ? <AddedSpan>{convertBreaks(c.value)}</AddedSpan> : <span>{convertBreaks(c.value)}</span>)
      }
    </div>),
  diffB: (<div>
      {
        changes.filter(c => !c.removed).map(c => c.added ? <AddedSpan>{convertBreaks(c.value)}</AddedSpan> : <span>{convertBreaks(c.value)}</span>)
      }
    </div>)
  }
}

interface DiffDescriptionType {
  description: (profile:(ProfileType | undefined), value: any) => ReactNode,
  filterKey: string[],
  uniqueKey: (path:string[]) => string,
  icon?: (profile:(ProfileType | undefined), value: any) => ReactNode,
  renderer?: (profile:(ProfileType | undefined), value: any) => ReactNode,
  descriptionAction?: (profile:(ProfileType | undefined), value: any) => ReactNode,
}

interface ContainerType {
  children?:Record<string, DiffDescriptionRecordType>
  arrayChildren?:Record<string, DiffDescriptionRecordType>
}

type DiffDescriptionRecordType = (DiffDescriptionType & ContainerType) | ContainerType

const diffDescriptions:Record<string, DiffDescriptionRecordType> = {
  "image": {
    description: () => "Consultant-Bild",
    filterKey: ["", "consultantData", "image"],
    uniqueKey: () => "consultantData::image",
    renderer: (profile:(ProfileType | undefined)) => profile?.image ? <ImageTransformContainer imageUrl={profile.image} transform={profile.imageTransform} /> : "Nicht vorhanden"
  },
  "imageTransform": {
    description: () => "Consultant-Bild",
    filterKey: ["", "consultantData", "image"],
    uniqueKey: () => "consultantData::image",
    renderer: (profile:(ProfileType | undefined)) => profile?.image ? <ImageTransformContainer imageUrl={profile.image} transform={profile.imageTransform} /> : "Nicht vorhanden"
  },
  "degree": {
    description: () => "Consultant Abschluss",
    filterKey: ["", "consultantData", "degree"],
    uniqueKey: () => "consultantData::degree",
  },
  "role": {
    description: () =>  "Consultant Rolle",
    filterKey: ["", "consultantData", "role"],
    uniqueKey: () => "consultantData::role",
  },
  "contact": {
    children: {
      "contactLine1": {
        description: () => "Adresszeile 1",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::contactLine1",
      },
      "contactLine2": {
        description: () => "Adresszeile 2",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::contactLine2",
      },
      "contactLine3": {
        description: () => "Adresszeile 3",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::contactLine3",
      },
      "contactLine4": {
        description: () => "Adresszeile 4",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::contactLine4",
      },
      "contactLine5": {
        description: () => "Adresszeile 5",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::contactLine5",
      },
      "phoneNumber": {
        description: () => "Telefonnummer",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::phoneNumber",
      },
      "mobileNumber": {
        description: () => "Mobil",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::mobileNumber",
      },
      "email": {
        description: () => "E-Mail",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::email",
      },
      "website": {
        description: () => "Webseite",
        filterKey: ["contact", "content"],
        uniqueKey: () => "contact::website",
      },
    },
  },
  "conditions": {
    children: {
      "rateOffSite": {
        description: () => "Stundensatz remote",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:rateOffSite"
      },
      "rateOnSite": {
        description: () => "Stundensatz on-site",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:rateOnSite"
      },
      "siteLocation": {
        description: () => "On-site Standort",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:siteLocation"
      },
      "availabilityPercent": {
        description: () => "Auslastung",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:availabilityPercent"
      },
      "availabilityOnSite": {
        description: () => "Verfügbarkeit on-site",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:availabilityOnSite"
      },
      "availabilityDate": {
        description: () => "Verfügbarkeit ab",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:availabilityDate"
      },
      "allowBodyLeasing": {
        description: () => "ANÜ",
        filterKey: ["conditions", "content"],
        uniqueKey: () => "conditions:allowBodyLeasing"
      },
    }
  },
  "projects": {
    description: (_, value:Project) => <>Projekt <em>{value.title}</em></>,
    filterKey: ["project", "existing"],
    uniqueKey: () => "project",
    arrayChildren: {
      "title": {
        description: (_, value:Project) => <>Projekt Titel</>,
        filterKey: ["project", "title"],
        uniqueKey: (path) => `project.${path[1]}.title`
      },
      "startDate": {
        description: (_, value:Project) => <>Projekt Startdatum</>,
        filterKey: ["project", "startDate"],
        uniqueKey: (path) => `project.${path[1]}.startDate`
      },
      "endDate": {
        description: (_, value:Project) => <>Projekt Enddatum</>,
        filterKey: ["project", "endDate"],
        uniqueKey: (path) => `project.${path[1]}.endDate`
      },
      "description": {
        description: (_, value:Project) => <>Projekt Beschreibung</>,
        filterKey: ["project", "description"],
        uniqueKey: (path) => `project.${path[1]}.description`
      },
      /*
      "skills": {
        arrayChildren: {
          "text": {
            description: (_, value:Project) => <>Projekt Skill</>,
            filterKey: ["project", "skill"],
            uniqueKey: (path) => `project.${path[1]}.skills.${path[3]}`
          }
        }
      }
      */
    }
  },
}

const getDiffDescription = (path:string[]):[DiffDescriptionType, number] => {
  let elem:Record<string, DiffDescriptionRecordType> = diffDescriptions
  let result:DiffDescriptionRecordType | null = null
  let idx:number = -1
  for(let i = 0; i < path.length; i++) {
    const p = path[i]
    result = elem[p] as DiffDescriptionRecordType
    idx = Object.keys(elem).indexOf(p)
    if(i < path.length - 1 && path[i + 1].match(/\[[0-9a-f]{32}\]/)) {
      elem = result?.arrayChildren || {}
      i++
    } else if(i < path.length - 1) {
      elem = result?.children || {}
    }
  }
  //console.log("diff description", path, result)
  return [result as DiffDescriptionType, idx]
}

function recursiveDiff(
    profileA:ProfileType | undefined, 
    profileB: ProfileType | undefined, 
    parentPath:string[], 
    a:any, 
    b:any, 
    changes:ChangeType[], 
    copyProfileA: (object: any, key: string, replacement: any) => ProfileType, 
    copyProfileB: (object: any, key: string, replacement: any) => ProfileType,
    sortKey: string,
    ):void {
  for(let key of Object.keys(a)) {
    const path = [...parentPath, key]
    const pathStr = path.join(".").replace(/\[[a-f0-9]{32}\]/g, '[x]')

    let [diffDescription, idx] = getDiffDescription(path)

    //console.log("found key ", key, typeof a[key], Array.isArray(a[key]))
    if(Array.isArray(a[key])) {
      for(let i = 0; i < a[key].length; i++) {
        const aArrayElem = a[key][i]
        const bArrayElem = Array.isArray(b[key]) && b[key].find((e: {ident:string}) => e.ident === aArrayElem.ident)
        if(bArrayElem) {
          if(aArrayElem.active !== undefined && diffDescription) {
            const diffDescriptionFilterKey = diffDescription.filterKey
            const diffDescriptionFilterKeyLength = diffDescriptionFilterKey.length
            changes.push({ 
              key: diffDescriptionFilterKey.map((e, idx) => idx === diffDescriptionFilterKeyLength - 1 ? "active" : e),
              uniqueKey: `${diffDescription.uniqueKey(path)}.[${aArrayElem.ident}].active`,
              sortKey: `${sortKey}.${idx}.${i}.0`,
              changed: aArrayElem.active !== bArrayElem.active,
              description: diffDescription.description(profileA, aArrayElem), 
              textA: <><em>{aArrayElem.active ? 'aktiv' : 'nicht aktiv'}</em></>,
              textB: <><em>{bArrayElem.active ? 'aktiv' : 'nicht aktiv'}</em></>,
              iconA: aArrayElem.active ? <CloseOutlined /> : <CheckSquareOutlined />,
              actionA: () => copyProfileA(a, key, a[key].filter(e => e !== aArrayElem)),
              descriptionActionA: <>{diffDescription.description(profileA, aArrayElem)} {aArrayElem.active ? 'deaktivieren' : 'aktivieren' }</>,
              iconB: bArrayElem.active ? <CloseOutlined /> : <CheckSquareOutlined />,
              actionB: () => copyProfileB(b, key, b[key]),
              descriptionActionB: <>{diffDescription.description(profileA, aArrayElem)} {aArrayElem.active ? 'aktivieren' : 'deaktivieren' }</>,
            })
          }

          const childCopyProfileA = (childObject:any, childKey:string, childReplacement:any): ProfileType => {
            const replacement = {...childObject, [childKey]: childReplacement}
            return copyProfileA(a, key, a[key].map(e => e === aArrayElem ? replacement : e))
          }
          const childCopyProfileB = (childObject:any, childKey:string, childReplacement:any): ProfileType => {
            const replacement = {...childObject, [childKey]: childReplacement}
            return copyProfileA(b, key, b[key].map(e => e === bArrayElem ? replacement : e))
          }
          recursiveDiff(profileA, profileB, [...path, `[${aArrayElem.ident}]`], aArrayElem, bArrayElem, changes, childCopyProfileA, childCopyProfileB, `${sortKey}.${idx}.${i}`)
        } else {
          const insertClone = <T extends {ident: string}>(from:T[], into:T[], idx:number):T[] => {
            let result:T[]
            const elem = JSON.parse(JSON.stringify(from[idx]))
            if(from.length > idx + 1 && into.length > idx && from[idx + 1].ident === into[idx].ident) {
              result = [...into]
              result.splice(idx, 0, elem)
            } else {
              result = [...into, elem]
            }
            return result
          }
          if(diffDescription) {
            changes.push({ 
              key: diffDescription.filterKey,
              uniqueKey: diffDescription.uniqueKey(path),
              sortKey: `${sortKey}.${idx}`,
              changed: a[key] !== b[key],
              description: diffDescription.description(profileA, aArrayElem), 
              textA: <><em>{diffDescription.description(profileA, aArrayElem)} vorhanden</em></>,
              textB: <><em>{diffDescription.description(profileA, aArrayElem)} nicht vorhanden</em></>,
              iconA: <CloseOutlined />,
              actionA: () => copyProfileA(a, key, a[key].filter(e => e !== aArrayElem)),
              descriptionActionA: <>{diffDescription.description(profileA, aArrayElem)} entfernen</>,
              iconB: <ArrowRightOutlined />,
              actionB: () => copyProfileB(b, key, insertClone(a[key], b[key], i)),
              descriptionActionB: <>{diffDescription.description(profileA, aArrayElem)} hinzufügen</>,
            })
          } else {
            console.log("No diff description found", pathStr)
          }
        }
      }
    } else if(a[key] && typeof a[key] === "object") {
      if(b[key]) {
        const childCopyProfileA = (childObject: any, childKey: string, childReplacement:any): ProfileType => {
          const replacement = {...childObject, [childKey]: childReplacement}
          return copyProfileA(a, key, replacement)
        }
        const childCopyProfileB = (childObject: any, childKey: string, childReplacement:any): ProfileType => {
          const replacement = {...childObject, [childKey]: childReplacement}
          return copyProfileB(b, key, replacement)
        }
        recursiveDiff(profileA, profileB, path, a[key], b[key], changes, childCopyProfileA, childCopyProfileB, `${sortKey}.${idx}`)
      }
    } else {
      if(!diffDescription && path[path.length - 1].endsWith("Active")) {
        const basePath = path.map((e, idx) => idx === path.length - 1 ? e.substring(0, e.length - "Active".length) : e)
        const [baseDiffDescription, baseIdx] = getDiffDescription(basePath)
        //console.log("Found active attribute", path, baseDiffDescription, basePath)
        if(baseDiffDescription) {
          diffDescription = {
            description: (profile: ProfileType | undefined, value:boolean) => `${baseDiffDescription.description(profile, value)} aktiv`,
            filterKey: baseDiffDescription.filterKey.map((f, idx) => (idx === baseDiffDescription.filterKey.length - 1) ? `${f}Active` : f),
            uniqueKey: (path) => baseDiffDescription.uniqueKey(path) + "Active",
            renderer: (_: ProfileType | undefined, value:boolean) => value ? "Aktiv" : "Nicht aktiv",
            icon: (_: ProfileType | undefined, value:boolean) => value ? <CloseOutlined /> : <CheckSquareOutlined />,
            descriptionAction: (profile: ProfileType | undefined, value:boolean) => value ? `${baseDiffDescription.description(profile, value)} deaktivieren` : `${baseDiffDescription.description(profile, value)} aktivieren`
          }
          idx = baseIdx
        }
      }
      if(diffDescription) {
        const callRenderTextDiff = () => {
          const { diffA, diffB } = renderTextDiffs(a[key] || '', b[key] || '')
          return { textA: diffA, textB: diffB }
        }
        const { textA, textB } = diffDescription.renderer ? { textA: diffDescription.renderer(profileA, a[key]), textB: diffDescription.renderer(profileB, b[key]) } : callRenderTextDiff()

        const existingDiff = changes.find(c => c.uniqueKey === diffDescription.uniqueKey(path))
        //console.log("Found diff description", diffDescription)

        if(existingDiff) {
          const wasChanged = existingDiff.changed

          existingDiff.changed = existingDiff.changed || a[key] !== b[key]

          //console.log("Found existing diff", existingDiff)
          existingDiff.iconA = wasChanged ? existingDiff.iconA : (b[key] ? <ArrowLeftOutlined /> : <CloseOutlined />)
          existingDiff.iconB = wasChanged ? existingDiff.iconB : <ArrowRightOutlined />

          existingDiff.descriptionActionA = wasChanged ? existingDiff.descriptionActionA : (b[key] ? <>{diffDescription.description} übernehmen</> : <>{diffDescription.description} entfernen</>)
          existingDiff.descriptionActionB = wasChanged ? existingDiff.descriptionActionB : (b[key] ? <>{diffDescription.description} übernehmen</> : <>{diffDescription.description} hinzufügen</>)

          existingDiff.textA = textA
          existingDiff.textB = textB

          const prevActionA = existingDiff.actionA
          existingDiff.actionA = () => { 
            const p = prevActionA(); 
            return copyProfileA(p, key, b[key])
          }      
          const prevActionB = existingDiff.actionB
          existingDiff.actionB = () => { 
            const p = prevActionB();
            return copyProfileB(p, key, a[key])
          }
          
        } else {
          changes.push({ 
            key: diffDescription.filterKey,
            uniqueKey: diffDescription.uniqueKey(path),
            sortKey: `${sortKey}.${idx}`,
            changed: a[key] !== b[key],
            description: diffDescription.description(profileA, a[key]), 
            textA,
            textB,
            iconA: diffDescription.icon ? diffDescription.icon(profileA, a[key]) : (b[key] ? <ArrowLeftOutlined /> : <CloseOutlined />),
            actionA: () => copyProfileA(a, key, b[key]),
            descriptionActionA: diffDescription.descriptionAction ? diffDescription.descriptionAction(profileA, a[key]) : (b[key] ? <>{diffDescription.description} übernehmen</> : <>{diffDescription.description} entfernen</>),
            iconB: diffDescription.icon ? diffDescription.icon(profileB, b[key]) : <ArrowRightOutlined />,
            actionB: () => copyProfileB(b, key, a[key]),
            descriptionActionB: diffDescription.descriptionAction ? diffDescription.descriptionAction(profileB, b[key]) :  (b[key] ? <>{diffDescription.description} übernehmen</> : <>{diffDescription.description} hinzufügen</>),
          })        
        }
      } else {
        console.log("No diff description found", pathStr)
      }
    }


  }
}

export function diffProjects(profileVariantA:ProfileType, profileVariantB:ProfileType):ChangeType[] {
  const recursiveDiffChanges:ChangeType[] = []
  const copyProfileA = (object: any, key:string, replacement: any) => ({...object, [key]: replacement})
  const copyProfileB = (object: any, key:string, replacement: any) => ({...object, [key]: replacement})
  recursiveDiff(profileVariantA, profileVariantB, [], profileVariantA, profileVariantB, recursiveDiffChanges, copyProfileA, copyProfileB, '')
  recursiveDiffChanges.sort((ca, cb) => ((ca.sortKey && cb.sortKey && ca?.sortKey.localeCompare(cb.sortKey)) || 0))

  console.log("Comparing profiles", profileVariantA, profileVariantB)

  return recursiveDiffChanges
}
