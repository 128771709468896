import { useCallback, useState, useMemo } from 'react'
import { CloseOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import styled from 'styled-components'
import ImageTransformContainer from './image-transform-container'

const DropArea = styled.div`
  width: 140px;
  height: 140px;
  border: 2px dashed grey;
  border-radius: 70px;
  text-align: center;
  padding-top: 4em;
`

const ImageTransformContainerStyled = styled(ImageTransformContainer)`
  cursor: grab;

  &.positioning-image {
    cursor: grabbing;
  }
`

interface PositionType {
  x: number,
  y: number
}

const ProfileImageSelectorInner = ({ value: transformValue, onChange: transformOnChange, imageValue, imageOnChange }:any) => {
  const [positioningImage, setPositioningImage] = useState<PositionType | null>(null)
  //const [transform, setTransform] = useState<string>("translate(0px, 0px) scale(1.0)");
 // const [translation, setTranslation] = useState<PositionType>({x: 0, y: 0})
  //const [scale, setScale] = useState<number>(1.0)

  const loadFile = useCallback((file:File) => {
    const fileReader:FileReader = new FileReader();
    fileReader.addEventListener('load', (evt) => {
      //console.log("Loaded image", evt.target?.result)
      imageOnChange(evt.target?.result)
    });
    fileReader.readAsDataURL(file)
  }, [imageOnChange])

  const dropHandler = useCallback((evt) => {
    console.log("File(s) dropped");
  
    // Prevent default behavior (Prevent file from being opened)
    evt.preventDefault();
  
    if (evt.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...evt.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file:File = item.getAsFile();
          loadFile(file)
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...evt.dataTransfer.files].forEach((file:File, i) => {
        loadFile(file)
      });
    }
  }, [loadFile])

  const { translate, scale }: { translate:PositionType, scale:number} = useMemo(() => {
    const parts = (transformValue || "translate(0px, 0px) scale(1.0)").split(" scale")
    const transformPart = parts[0].replace("translate(", "").replace(")", "").replaceAll("px", "")
    const [x, y] = transformPart.split(", ").map(v => parseFloat(v))
    const scalePart = parts[1].replace("(", "").replace(")", "")
    const scale = parseFloat(scalePart)
    
    return {
      translate: { x, y },
      scale
    }
  }, [transformValue])

  const setScale = useCallback((scale:number) => { transformOnChange(`translate(${Math.round(translate.x)}px, ${Math.round(translate.y)}px) scale(${scale.toFixed(2)})`) }, [translate, transformOnChange])
  const setTranslate = useCallback(({ x, y }:PositionType) => { transformOnChange(`translate(${Math.round(x)}px, ${Math.round(y)}px) scale(${scale.toFixed(2)})`) }, [scale, transformOnChange])

  return (
    <div style={{ display: "flex", flexDirection: "column" , alignItems: "center"}}>
      { !imageValue && <DropArea onDrop={dropHandler} onDragOver={evt => evt.preventDefault()}>Drag & Drop Bild hier</DropArea> }
      { imageValue && 
        <ImageTransformContainerStyled 
          transform={transformValue}
          imageUrl={imageValue}
          className={positioningImage ? "positioning-image" : undefined} 
          onDragStart={evt => evt.preventDefault() } 
          onMouseDown={evt => setPositioningImage({ x: evt.clientX, y: evt.clientY })} 
          onMouseUp={() => setPositioningImage(null)} 
          onMouseLeave={() => setPositioningImage(null)}  
          onMouseMove={(evt) => { 
            if(positioningImage) {
              const newPosition:PositionType = { x: evt.clientX - positioningImage.x, y: evt.clientY - positioningImage.y }
              setTranslate(newPosition)
            }
          }}
        />
         }
        <div style={{ marginTop: "1em" }}>
          <Button icon={<CloseOutlined />} style={{ marginRight: "2em", visibility: "hidden"}} onClick={() => imageOnChange(null)}/>
          <Button icon={<ZoomInOutlined />} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setScale(scale * 1.1)} disabled={scale > 1.5} />
          <Button icon={<ZoomOutOutlined />} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: "-1px"}} onClick={() => setScale(scale / 1.1)} disabled={scale < 0.5}/>

          <Button icon={<CloseOutlined />} style={{ marginLeft: "2em"}} onClick={() => imageOnChange(null)}/>
        </div>
    </div>
  )
}

function ProfileImageSelector({ value, onChange, nameImageTransform }:any) {
  return (
    <Form.Item name={nameImageTransform}>
      <ProfileImageSelectorInner imageValue={value} imageOnChange={onChange} />
    </Form.Item>
  )
}

export default ProfileImageSelector