import { Form, Input, Modal, Select } from "antd";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useSendFeedbackMutation } from "../../api/feedback-api";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import SnapshotField from "./snapshot-field";

interface FeedbackModelPropsType {
  baseZIndex?:number,
  takeScreenshot: () => Promise<string>
}

const FeedbackModal = forwardRef(({ takeScreenshot, baseZIndex = 200000 }: FeedbackModelPropsType, ref) => {
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false)
  const [sendFeedbackApiCall, { isSuccess: successSendingFeedback }] = useSendFeedbackMutation()
  const [startedSendingFeedback, setStartedSendingFeedback] = useState<boolean>(false)
  const [form] = Form.useForm()

  useImperativeHandle(ref, () => ({
    show: () => {
      form.resetFields()
      setStartedSendingFeedback(false)
      setShowFeedbackForm(true)
    }
  }))

  const onSend = useCallback(() => {
    setStartedSendingFeedback(true)
    const values = form.getFieldsValue(true)
    console.log("Feedback form values", values)
    sendFeedbackApiCall(values)
  }, [sendFeedbackApiCall, form, setStartedSendingFeedback])

  useEffect(() => {
    if(successSendingFeedback) {
      setTimeout(() => {
        setShowFeedbackForm(false)
      }, 3000)
    }
  }, [successSendingFeedback, setShowFeedbackForm])

  const setModalChildRef = useCallback((ref) => {
    while(ref && ref.getAttribute && (ref.getAttribute("class") === null || ref.getAttribute("class").indexOf("ant-modal-root") === -1)) {
      ref = ref.parentNode
    }
    if(ref) {
      ref.toImage = () => null
      ref.cloneChildren = () => null
    }
  }, [])

  return (
    <Modal 
        title="Feedback geben / Fehler melden" 
        open={showFeedbackForm} 
        onOk={onSend}
        okButtonProps={{ disabled: startedSendingFeedback, icon: startedSendingFeedback ? <LoadingOutlined /> : undefined }}
        onCancel={() => setShowFeedbackForm(false)} 
        cancelButtonProps={{ disabled: startedSendingFeedback }}
        zIndex={baseZIndex} 
        width={700}
        >
      { (!startedSendingFeedback || !successSendingFeedback) && <Form 
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{ width: 600 }}
        autoComplete="off"
      >
        <span ref={setModalChildRef} />
        <Form.Item label="Art" name={["type"]}>
          <Select dropdownStyle={{ zIndex: baseZIndex + 1 }} disabled={ startedSendingFeedback } options={[
            {
              value: "BUG",
              label: "Fehler / Bug"
            },
            {
              value: "QUESTION",
              label: "Frage"
            },
            {
              value: "FEATURE_REQUEST",
              label: "Änderungswunsch"
            },
            {
              value: "GENERAL_FEEDBACK",
              label: "Allgemeines Feedback"
            }
          ]}  />
        </Form.Item>
        <Form.Item label="Screenshot" name={["image"]}>
          <SnapshotField takeScreenshot={takeScreenshot} baseZIndex={baseZIndex + 1}/>
        </Form.Item>
        <Form.Item label="Beschreibung" name={["description"]}>
          <Input.TextArea style={{ height: 350 }} disabled={ startedSendingFeedback } />
        </Form.Item>
      </Form> }
      { startedSendingFeedback && successSendingFeedback && 
        <div style={{ display: "flex", gap: 16, marginTop: "0.4em" }} >
          <div><CheckCircleOutlined style={{ fontSize: "500%", color: "green" }}/></div>
          <div><h1>Vielen Dank!</h1><p>Wir haben das Feedback erhalten und werden es zeitnah prüfen.</p></div>
        </div>
      }
    </Modal>
  )
})

export default FeedbackModal

/*
<Form
                ref={formRef}
                form={form}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ maxWidth: 600 }}
                onFieldsChange={onFormChange}
                onFinishFailed={onFinishFailed}
                initialValues={values}
                autoComplete="off"
              >*/