import { Form, FormInstance } from "antd"

interface FormValueTextPropsType {
  name: (number | string)[],
  form: FormInstance<any>,
  default?: any 
}

function FormValueText({ name, form, default: dflt = "" }: FormValueTextPropsType) {
  const value = Form.useWatch(name, { form, preserve: true })
  return (<>{ value || dflt }</>)
}

export default FormValueText