import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserType {
  userToken: string | null
}

const initialState:UserType = {
    userToken: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserToken(state, { payload: { userToken } }: PayloadAction<{ userToken: string}>) {
            state.userToken = userToken;
        }
    },
})

export const {
    setUserToken
} = userSlice.actions;

export default userSlice.reducer;