import { LoadingOutlined } from "@ant-design/icons"


function LoadingOverlay({ isLoading, loadingLabel }) {
  //console.log("Loading overlay is loading", isLoading, new Date().valueOf())
  return (
    <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", opacity: isLoading ? 1 : 0, position: "absolute", top: 0, left: 0, width: "100%", height: "100%", transition: "opacity 1s", display: "flex", flexDirection: "column", zIndex: 200, pointerEvents: isLoading ? undefined : "none" }}>
      <div style={{ flex: 1 }} />
      <div style={{ textAlign: "center" }}>
        <span style={{ backgroundColor: "black", color: "white", padding: "1em", borderRadius: "2em" }}>
          <LoadingOutlined /> { loadingLabel }
        </span>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  )
}

export default LoadingOverlay