import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithPrepareHeaders } from "./util";

const BASE_URL = '/api/profile'

const baseQuery = fetchBaseQueryWithPrepareHeaders({
  baseUrl: `${BASE_URL}/`,
})

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery,
  tagTypes: ['Profile'],
  endpoints: (builder:any) => ({
    getProfilesByConsultantIdents: builder.query({
      query: (consultantIdents:string[]) => ({
        url: 'profile?f=' + encodeURIComponent(JSON.stringify({ 'pk.consultant.ident': { c: consultantIdents.length === 1 ? "EQ" : "IN", v: consultantIdents.length === 1 ? consultantIdents[0] : consultantIdents} })) + '&st=' + encodeURIComponent(JSON.stringify({ 'pk.variant': 'ASC' })),
      }),
      providesTags: ['Profile']
    }),
    getProfilesByConsultantIdentsLight: builder.query({
      query: (consultantIdents:string[]) => ({
        url: 'profile-light?f=' + encodeURIComponent(JSON.stringify({ 'pk.consultant.ident': {  c: consultantIdents.length === 1 ? "EQ" : "IN", v: consultantIdents.length === 1 ? consultantIdents[0] : consultantIdents } })) + '&st=' + encodeURIComponent(JSON.stringify({ 'pk.variant': 'ASC' })),
      }),
      providesTags: ['ProfileLight']
    }),
    getProfileByIdent: builder.query({
      query: (name) => `profile/${name}`,
      providesTags: ['Profile']
    }),
    updateProfileByIdent: builder.mutation({
      query: ({id, item}) => ({
        url: `profile/${id}`,
        method: 'PUT',
        body: item
      }),
      invalidatesTags: ['Profile', 'ProfileLight']
    }),
    deleteProfileByIdent: builder.mutation({
      query: ({id}:{id:string}) => ({
        url: `profile/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Profile', 'ProfileLight']
    }),
    renderProfileMutation: builder.mutation({
      query: ({item, format}) => ({
        url: 'profile/convert',
        method: 'POST',
        body: item,
        headers: {
          accept: format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        responseHandler: async (response) => { 
          const blob = await response.blob()
          const url = URL.createObjectURL(blob)
          return url
        },
      }),
    }),
    createProfileVariant: builder.mutation({
      query: ({baseIdent, description, keepKeywords}) => ({
        url: `profile/${baseIdent}/clone`,
        method: 'POST',
        body: {
          description, keepKeywords
        },
      }),
      invalidatesTags: ['Profile', 'ProfileLight']
    })
  })
})

export const { 
  useGetProfilesByConsultantIdentsQuery,
  useGetProfilesByConsultantIdentsLightQuery,
  useGetProfileByIdentQuery,
  useUpdateProfileByIdentMutation,
  useRenderProfileMutationMutation,
  useCreateProfileVariantMutation,
  useDeleteProfileByIdentMutation,
} = profileApi