import { Fragment, useCallback, useState, useEffect, useMemo, ReactNode } from 'react'
import { SwapOutlined } from "@ant-design/icons"
import { styled } from 'styled-components'

interface DropAreaStylePropsType {
  $active: boolean,
  $dragged: boolean,
}

interface DropAreaPropsType {
  active: boolean,
  dragged: boolean,
  renderChild: () => ReactNode,
  onDrop: () => void,
}

const DropAreaStyle = styled.div<DropAreaStylePropsType>`
  border: 2px dashed gray;
  min-height: 8px;
  ${(props:DropAreaStylePropsType) => !props.$active ? 'visibility: hidden;' : undefined}
  ${(props:DropAreaStylePropsType) => props.$dragged ? 'display: none;' : undefined}

  & > div {
    opacity: 0.5;
  }
`

const DropArea = ({active, dragged, renderChild, onDrop}:DropAreaPropsType) => {
  const [draggingOver, setDraggingOver] = useState(false)

  useEffect(() => {
    if(!active) {
      setDraggingOver(false)
    }
  }, [active, setDraggingOver])

  const onDragEnter = useCallback(() => {
    setDraggingOver(true)
  }, [setDraggingOver])

  const onDragExit = useCallback(() => {
    setDraggingOver(false)
  }, [setDraggingOver])

  const onDragEnd = useCallback(() => {
    setDraggingOver(false)
  }, [setDraggingOver])

  return (
    <DropAreaStyle $active={active} $dragged={dragged} onDragEnter={onDragEnter} onDragExit={onDragExit} onDragEnd={onDragEnd} onDrop={onDrop}>
      {draggingOver && <div>
        { renderChild() }
      </div>}
    </DropAreaStyle>
  )
}

interface ReorderContainerPropsType<Item> {
  type: string,
  style?: object,
  elems: Item[],
  calcKey?: (e:Item) => string,
  children: (e:Item, idx:number, moveIcon:ReactNode) => ReactNode,
  onReorder: (from:number, to:number) => void
  gap?: number,
}

function ReorderContainer<Item>({ type, style = {}, elems, calcKey, children, onReorder, gap }: ReorderContainerPropsType<Item>) {

  const [draggedIdx, setDraggedIdx] = useState(-1)
  const [draggingOver, setDraggingOver] = useState(false)

  const onDragStart = useCallback((evt, idx) => {
    evt.dataTransfer.setData("type", type);
    setDraggedIdx(idx)
  }, [setDraggedIdx, type])

  const onDragOver = useCallback(evt => {
    if(evt.dataTransfer.getData("type") === type) {
      evt.preventDefault()
    }
  }, [type])

  const onDragEnter = useCallback(evt => {
    if(evt.dataTransfer.getData("type") === type) {
      setDraggingOver(true)
    }
  }, [setDraggingOver, type])

  const onDragExit = useCallback(() => {
    setDraggingOver(false)
  }, [setDraggingOver])

  const onDragEnd = useCallback(() => {
    setDraggingOver(false)
    setDraggedIdx(-1)
  }, [setDraggingOver])

  const draggedElem = useMemo(() => elems[draggedIdx], [elems, draggedIdx])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: gap ? (Math.max(0, (gap - 4) / 2)) : 6, ...style }} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragExit={onDragExit} onDragEnd={onDragEnd}>
      { 
        elems.map((e, idx) => {
          const moveIcon = <SwapOutlined style={{ transform: 'rotate(90deg)', cursor: 'move' }} draggable onDragStart={evt => onDragStart(evt, idx)}/>
          return (<Fragment key={calcKey ? calcKey(e) : idx}>
            <DropArea active={draggingOver} dragged={draggedIdx === idx} renderChild={() => draggedElem && children(draggedElem, draggedIdx, null)} onDrop={() => { onReorder(draggedIdx, idx); setDraggingOver(false); setDraggedIdx(-1); }}/>
            <div style={{ display: draggedIdx === idx ? 'none' : undefined }}>
            { children(e, idx, moveIcon) }
            </div>
          </Fragment>)
        })
      }
      <DropArea active={draggingOver} dragged={false} renderChild={() => draggedElem && children(elems[draggedIdx], draggedIdx, null)} onDrop={() => { onReorder(draggedIdx, elems.length); setDraggingOver(false); setDraggedIdx(-1); }} />
    </div>
  )
}

export default ReorderContainer