import { useCallback, useRef } from 'react'
import { styled } from 'styled-components'
import FeedbackModal from './feedback-modal'
import domtoimage from './dom-to-image'

const ButtonStyle = styled.div`
  position: absolute;
  right: -5.5em;
  top: 50%;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  background-color: orange;
  padding: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  color: #EEEEEE;
  font-weight: bold;
  right: 0;
  cursor: pointer;
  z-index: 100000;

  &:hover {
    color: white;
  }
`

function FeedbackButton() {
  const feedbackModalRef = useRef<any>()

  const takeScreenshot = useCallback(():Promise<string> => {
    return new Promise((resolve, reject) => {
      const toImg:any = domtoimage.toJpeg
      toImg(window.document.body).then(resolve).catch((e:any) => {
        console.log("Error creating jpeg", e, e.message)
        reject(e)
      })
    })
  }, [])

  const setButtonRef = useCallback((ref) => {
    ref.toImage = () => null
    ref.cloneChildren = () => null
  }, [])

  return <>
    <FeedbackModal ref={feedbackModalRef} takeScreenshot={takeScreenshot} />
    <ButtonStyle ref={setButtonRef} onClick={() => feedbackModalRef.current?.show()}>Feedback / Fehler melden</ButtonStyle>
  </>
}

export default FeedbackButton