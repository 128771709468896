import { createContext, useContext, useState, useCallback } from 'react'
import { Breadcrumb as AntdBreadcrumb, Space } from "antd";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';

interface BreadcrumbContextValueType {
  items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]
  setItems: (items:Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]) => void
}

const breadcrumbContextDefaultValue = { items: [], setItems: () => {}}

const BreadcrumbContext = createContext<BreadcrumbContextValueType>(breadcrumbContextDefaultValue)

export const useBreadcrumbContext = () => {
  return useContext(BreadcrumbContext)
}

export const BreadcrumbWrapper = ({ children }) => {
  const [items, setItems] = useState<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]>([])

  return (
    <BreadcrumbContext.Provider value={{ items, setItems }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

function Breadcrumb() {
  const value:BreadcrumbContextValueType = useBreadcrumbContext()

  const itemRender = useCallback((item, _2, items, paths) => {
    return <Space>{ item.title }</Space>
  }, [])

  return (
    <AntdBreadcrumb style={{ margin: '16px 0' }} items={value.items} itemRender={itemRender} />
  )
}

export default Breadcrumb