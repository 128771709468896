import BuzzwordBingoInput from "./buzzword-bingo-input";

interface ExcludedKeywordsInputPropsType {
  value?:string[],
  onChange?: (value:string[]) => void,
}

function ExcludedKeywordsInput({ value, onChange }: ExcludedKeywordsInputPropsType) {
  return (
    <BuzzwordBingoInput value={value} onChange={onChange} />
  )
}

export default ExcludedKeywordsInput