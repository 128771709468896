import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";


export function fetchBaseQueryWithPrepareHeaders(config) {
  return fetchBaseQuery({
    ...config,
    prepareHeaders: (headers, {getState}) => {
      console.log("Preparing headers", getState())
      const token = (getState() as any).user?.userToken;
      if (token) {
          headers.set('Authorization', `Bearer ${token}`)
      }
      //console.log("Preparing headers", (getState() as any).user, headers)
      return headers;
  },
  })
}