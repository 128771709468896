import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface UiStateType {
  expandedMenuItems: string[],
  expandedCollapsibles: string[],
  siderExpanded: boolean,
}

const initialState:UiStateType = ({
  expandedMenuItems: [],
  expandedCollapsibles: [],
  siderExpanded: true
})

export const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setExpandedMenuItems: (state, { payload: { expanded } }: PayloadAction<{ expanded: string[] }>):void => {
      state.expandedMenuItems = expanded
    },
    setSiderExpanded: (state, { payload: { expanded } }: PayloadAction<{ expanded: boolean }>):void => {
      state.siderExpanded = expanded
    },
    expandCollapsible: (state, { payload: { key, expand } }: PayloadAction<{ key: string, expand: boolean }>):void => {
      state.expandedCollapsibles = [...(state.expandedCollapsibles || []).filter(k => k !== key), ...(expand ? [key] : [])]
    },
  },
})

export const { setExpandedMenuItems, setSiderExpanded, expandCollapsible } = uiStateSlice.actions

export default uiStateSlice.reducer