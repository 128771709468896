import { useState, useRef, useCallback, useEffect, forwardRef, useImperativeHandle, useMemo, ReactNode } from 'react'
import { LoadingOutlined } from "@ant-design/icons"
import { Button, Checkbox, Form, FormInstance, Progress } from "antd"
import PdfPreview from './pdf-preview'
import ScrollContainer from './scroll-container'
import LoadingOverlay from './loading-overlay'

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

interface FormWithPreviewChildrenArgsType {
  form: FormInstance<any>
}

interface FormWithPreviewPropsType {
  saving: boolean
  onSave: (values:any) => void
  loading: boolean
  children: (args:FormWithPreviewChildrenArgsType) => ReactNode,
  values: any
  buildRenderData?: (values:any) => any
}


const FormWithPreview = forwardRef(({ saving, onSave, loading, children, values, buildRenderData = (values) => values }:FormWithPreviewPropsType, ref) => {
  const [autosave, setAutosave] = useState<boolean>(false)
  const autosaveIntervalRef = useRef<any>()
  const onSaveRef = useRef<() => void>(() => {})
  const onFormChangeTimerRef = useRef<any>()
  const [form] = Form.useForm()
  const formRef = useRef<any>(null)
  const previewRef = useRef<any>()
  const [internalLoading, setInternalLoading] = useState<boolean>(false)
  const internalLoadingTimerRef = useRef<any>()

  useEffect(() => {
    clearTimeout(internalLoadingTimerRef.current)
    if(!loading) {
      internalLoadingTimerRef.current = setTimeout(() => {
        setInternalLoading(false)
      }, 1000)
    } else {
      setInternalLoading(true)
    }
  }, [setInternalLoading, internalLoadingTimerRef, loading])

  //console.log("Form with preview loading", loading, new Date().valueOf())

  useEffect(() => {
    formRef.current?.setFieldsValue(values)
    previewRef.current?.updatePreview(values)
  }, [formRef, values, previewRef])

  const onSaveInternal = useCallback(() => {
    const values = form.getFieldsValue(true)
    onSave(values)
  }, [form, onSave])

  const toogleAutosave = useCallback(() => {
    const newAutosave = !autosave
    setAutosave(newAutosave)
    if(!newAutosave) {
      clearInterval(autosaveIntervalRef.current)
      autosaveIntervalRef.current = null
    } else if(!autosaveIntervalRef.current) {
      autosaveIntervalRef.current = setInterval(() => {
        onSaveRef.current()
      }, 1 * 20 * 1000)
    }
  }, [autosave, setAutosave, autosaveIntervalRef, onSaveRef])

  const getData = useCallback(() => buildRenderData(form.getFieldsValue(true)), [form, buildRenderData])
  
  const onFormChange = useCallback(() => {
    clearTimeout(onFormChangeTimerRef.current)
    onFormChangeTimerRef.current = setTimeout(() => {
      const values = getData()
      console.log("Updating preview", values)
      previewRef.current?.updatePreview(values)
    }, 1000)
  }, [getData, onFormChangeTimerRef, previewRef])

  useEffect(() => {
    return () => {
      if(autosaveIntervalRef.current) {
        clearInterval(autosaveIntervalRef.current)
        autosaveIntervalRef.current = null
      }
    }   
  }, [])

  const getValues = useCallback(() => form.getFieldsValue(true), [form])

  useImperativeHandle(ref, () => {
    return {
      getValues,
      updateValues: (values) => {
        formRef.current?.setFieldsValue(values)
      },
    };
  }, [formRef, getValues]);

  const childElements = useMemo(() => children({ form }), [form, children])

  return (
    <div style={{ display: "flex", maxWidth: 1500, columnGap: 24, height: '100%', marginLeft: "auto", marginRight: "auto", position: "relative" }}>
      <div style={{ flex: 1, height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexFlow: "row", minWidth: 0, margin: 0, maxWidth: "600px", }}>
          <div style={{ flex: "0 0 25%", textAlign: "right" }} />
          <div style={{ flex: 1 }}>
            <Button type="primary" onClick={onSaveInternal}>Speichern</Button>
            <Checkbox style={{ marginLeft: '2em'}} onChange={toogleAutosave} checked={autosave} /> Autom. speichern
            { saving && <span style={{ marginLeft: "2em" }}><LoadingOutlined /> Wird gespeichert</span> }
          </div>
        </div>
        <div style={{ flex: 1, position: "relative", overflow: "auto" }}>
          <ScrollContainer style={{ height: "100%" }}>
              <Form
                ref={formRef}
                form={form}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ maxWidth: 600 }}
                onFieldsChange={onFormChange}
                onFinishFailed={onFinishFailed}
                initialValues={values}
                autoComplete="off"
              >
                { childElements }
              </Form>          
            </ScrollContainer>
          </div>
        </div>
      <PdfPreview ref={previewRef} getData={getData} />
      { false && <div style={{ position: "absolute", top: 0, right: 0}} >
        <Progress type='circle' size={40} />
      </div> }
      <LoadingOverlay isLoading={internalLoading} loadingLabel={<>Daten werden geladen &hellip;</>} />
    </div>
  )
})

export default FormWithPreview