import { BankOutlined, InfoCircleOutlined, PicRightOutlined, PlusCircleOutlined, SwapOutlined } from "@ant-design/icons"
import { Card, Col, Row, Space } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import { Consultant } from "../../types/consultant"
import { useGetConsultantsQuery } from "../../api/consultant-api"
import NewVariantForm from "../new-variant-form"
import { ProfileType } from "../../types/profile"
import { useGetProfilesByConsultantIdentsLightQuery } from "../../api/profile-api"

const WelcomeBox = styled.div`
  padding: 24px;
  border: 0.6px solid rgb(240, 240, 240);;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.88);
  max-width: 60em;
  box-shadow: 0px 0px 30px white;
  font-size: 16px;
  margin-bottom: 3em;
  margin-top: 5em;

  &.hidden {
    transform: translate(0, 100px);
    opacity: 0.0;
    box-shadow: none;
  }

  transition: opacity 1s, transform 1s, box-shadow 0.5s;

  .header {
    font-weight: bold;
  }
`

const FadeInCard = styled(Card)`
  width: 240px;
  transform: scale(1);

  &.hidden {
    transform: scale(0);
    opacity: 0.0;
  }

  transition: opacity 1s, transform 1s, box-shadow 0.5s;
`

function LandingPage() {
  const [displayCard, setDisplayCard] = useState<number>(0)
  const [welcomeBoxHidden, setWelcomeBoxHidden] = useState<boolean>(true)
  const { data: consultants}: {data?: Consultant[]} = useGetConsultantsQuery({}) as {data?: Consultant[]}
  const { data: profiles}: {data?: ProfileType[]} = useGetProfilesByConsultantIdentsLightQuery(consultants ? [consultants[0].ident] : [], { skip: !consultants}) as {data?: ProfileType[]}
  const navigate = useNavigate()
  const [createVariant, setCreateVariant] = useState<string | null>(null)
  
  useEffect(() => {
    let myDisplayCard = -2;
    const interval = setInterval(() => {
      myDisplayCard++;
      setDisplayCard(myDisplayCard);
      if(myDisplayCard > 4) {
        clearInterval(interval);
      }
    }, 250)
  }, [setDisplayCard])

  useEffect(() => {
    setTimeout(() => {
      setWelcomeBoxHidden(false)
    }, 250)
  }, [setWelcomeBoxHidden])

  const options = useMemo(() => [
    ...(consultants && consultants[0].tenant.timeCreated === consultants[0].tenant.timeUpdated ? [{
      key: "editCompany",
      icon: <BankOutlined />,
      label: "Firmendaten ausfüllen",
      onClick: () => navigate(`/company`)
    }] : []),
    ...(profiles && consultants && profiles[0].timeCreated === profiles[0].timeUpdated ? [{
      key: "editMainProfile",
      icon: <PicRightOutlined />,
      label: "Hauptprofil ausfüllen",
      onClick: () => navigate(`/profile/${(consultants[0] || {}).ident}-0`)
    }] : []),
    ...(profiles && consultants && profiles[0].timeCreated !== profiles[0].timeUpdated ? [{
      key: "newVariant",
      icon: <PlusCircleOutlined />,
      label: "Neue Profilvariante",
      onClick: () => consultants && setCreateVariant((consultants[0] || {}).ident)
    }] : []),
    ...(profiles && profiles.length > 1 ? [{
      key: "compareVariants",
      icon: <SwapOutlined />,
      label: "Varianten abgleichen",
      onClick: () => consultants && navigate(`/consultant/${(consultants[0] || {}).ident}/compare-profile-variants`)
    }] : []),
    {
      key: "help",
      icon: <InfoCircleOutlined />,
      label: "Hilfe"
    }
  ], [consultants, profiles, setCreateVariant, navigate])

  const onCompleteCreateVariant = useCallback((ident:string | null) => {
    setCreateVariant(null)
    if(ident) {
      navigate(`/profile/${ident}`)
    }
  }, [navigate, setCreateVariant])
  

  return (
    <>
      <NewVariantForm consultantIdent={createVariant} onComplete={onCompleteCreateVariant} />
      <Row style={{ marginTop: '2em' }}>
        <Col flex="1"></Col>
        <Col>
          <WelcomeBox className={welcomeBoxHidden ? 'hidden' : undefined}>
            <div className="header">Hello 123</div>
            <div className="text">
              Hello 456
            </div>
          </WelcomeBox>
        </Col>
        <Col flex="1"></Col>
      </Row>
      <Row style={{ marginTop: '2em' }}>
        <Col flex="1"></Col>
        <Col>
          <Space size="large">
            { 
              options.map((option, idx) => {
                return <FadeInCard
                  key={option.key}
                  onClick={option.onClick}
                  hoverable
                  className={[displayCard <= idx ? "hidden": undefined].filter(i => !!i).join(" ")}
                  cover={<div style={{ textAlign: "center", fontSize: '600%', padding: '0.5em', color: "rgba(0, 0, 0, 0.88)" }}>{ option.icon }</div>}
                >
                  <Card.Meta title={ <div style={{ textAlign: 'center' }}>{option.label}</div> } />
                </FadeInCard>
              })
            }
          </Space>
        </Col>
        <Col flex="1"></Col>
      </Row>
    </>
  )
}

export default LandingPage