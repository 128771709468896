import { CloseOutlined, SwapOutlined } from "@ant-design/icons";
import { Form, Input } from "antd"
import { ReactNode } from "react";
import AutoActiveCheckbox from "../auto-active-checkbox";

interface FormItemPropsType {
  label?: string,
  name?: object | number[],
  activeName?: object | number[],
  onDelete?: () => void,
  inputElem?: ReactNode,
  moveIcon?: ReactNode,
}

function FormItem({ label, name, activeName, onDelete, inputElem, moveIcon }: FormItemPropsType) {

  return (
    <Form.Item label={label}>
      <div style={{ display: "flex", columnGap: '12px' }}>
        <Form.Item noStyle name={name}>
          { inputElem }
          { !inputElem && <Input style={{ flex: 1 }} /> }
        </Form.Item>
        <Form.Item noStyle name={activeName} valuePropName="checked">
          <AutoActiveCheckbox style={{ visibility: activeName ? undefined : 'hidden' }} />
        </Form.Item>
        { moveIcon ?? <SwapOutlined style={{ transform: 'rotate(90deg)', visibility: 'hidden' }} />}
        <CloseOutlined style={{ visibility: onDelete ? undefined : 'hidden' }} onClick={() => onDelete && onDelete()} />
      </div>
    </Form.Item>
  )
}

export default FormItem;