import { RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Modal, Layout, Tooltip } from "antd";
import { useCallback, useRef, useState } from "react";
import styled from 'styled-components'
import domtoimage from "./dom-to-image";

const SelectButton = styled.div`
  padding: 0.5em;
  border: 1px solid #888;
  border-radius: 0.25em;
  margin-right: 0.5em;
  cursor: pointer;

  &.selected {
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.5); 
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  }
`

const Color = styled.div`
  width: 1.5em;
  height: 1.5em;
  background-color: ${props => props.color};
`

interface ElementType {
  type: string,
  origX: number,
  origY: number,
  x: number,
  y: number,
  width: number,
  height: number,
  rx: number,
  ry: number,
  stroke: string,
  strokeWidth: string,
  fill: string
}

export default function SnapshotPanel({ currentImage, baseZIndex, onFinish }) {
  const [imageDim, setImageDim] = useState({ x: 0, y: 0 })
  const imgRef = useRef<any>()
  const imageContainerRef = useRef<any>();
  const [elements, setElements] = useState<ElementType[]>([])
  const [undoneElements, setUndoneElements] = useState<ElementType[]>([])
  const [dragging, setDragging] = useState(false)
  const [selectedColor, setSelectedColor] = useState("#FF0000")
  const [selectedShape, setSelectedShape] = useState("rect")
  
  if(imgRef.current && imgRef.current.width !== imageDim.x && imgRef.current.height !== imageDim.y) {
    const newImageDim = { x: imgRef.current.width, y: imgRef.current.height }
    console.log("New image dim", newImageDim)
    setImageDim(newImageDim)
  }

  const getRelXYFromEvent = useCallback(e => {
    const boundingClientRect = imgRef.current.getBoundingClientRect()
    return { x: e.clientX - boundingClientRect.left, y: e.clientY - boundingClientRect.top}
  }, [])

  const startDragging = useCallback(e => {
    const {x, y} = getRelXYFromEvent(e)
    const newElement:ElementType = {
      type: selectedShape,
      origX: x, origY: y,
      x, y, width: 5, height: 5,
      rx: selectedShape === 'ellipse' ? 1000 : 0, 
      ry: selectedShape === 'ellipse' ? 1000 : 0,
      stroke: selectedColor,
      strokeWidth: "5",
      fill: "none"
    }
    setElements([...elements, newElement])
    setUndoneElements([])
    setDragging(true)
  }, [elements, setElements, setDragging, getRelXYFromEvent, selectedColor, selectedShape, setUndoneElements])

  const drag = useCallback(e => {
    if(dragging) {
      const {x, y} = getRelXYFromEvent(e)
      const lastElement = {...elements[elements.length -1]}
      lastElement.width = Math.abs(x - lastElement.origX)
      lastElement.height = Math.abs(y - lastElement.origY)
      lastElement.x = Math.min(x, lastElement.origX)
      lastElement.y = Math.min(y, lastElement.origY)
      const newElements = [...elements]
      newElements[newElements.length - 1] = lastElement
      setElements(newElements)
    }
  }, [dragging, elements, setElements, getRelXYFromEvent])

  const stopDragging = useCallback(e => {
    setDragging(false)
  }, [setDragging])

  const undo = useCallback(() => {
    const newElements = [...elements];
    const undoneElement:ElementType | undefined = newElements.pop()
    setElements(newElements)
    if(undoneElement) {
      const newUndoneElements:ElementType[] = [...undoneElements, undoneElement]
      setUndoneElements(newUndoneElements)
    }
  }, [elements, setElements, undoneElements, setUndoneElements])

  const redo = useCallback(() => {
    const newUndoneElements:ElementType[] = [...undoneElements];
    const redoneElement:ElementType | undefined = newUndoneElements.pop()
    if(redoneElement) {
      setUndoneElements(newUndoneElements)
      const newElements:ElementType[] = [...elements, redoneElement]
      setElements(newElements)
    }
  }, [undoneElements, setUndoneElements, elements, setElements])

  /*
  const finishEdit = useCallback(() => {
    //resetCurrentImage(null)
    //setElements([])
    //setUndoneElements([])
  }, [/*setElements, setUndoneElements, resetCurrentImage])
  */
  const saveImage = useCallback(async () => {
    const imageContainer = imageContainerRef.current
    const img = await domtoimage.toJpeg(imageContainer, {})
    onFinish(img)
  }, [imageContainerRef, onFinish])

  return <>
  <Modal 
      width={'80%'}
      zIndex={baseZIndex} 
      title="Bildanzeige" 
      open={!!currentImage} 
      onOk={saveImage}
      onCancel={() => onFinish(null)} >
    <Layout>
      <Layout.Content>
        <div style={{width: '100%', height: '100%', position: 'relative'}} ref={imageContainerRef}>
          <img ref={imgRef} style={{ width: '100%'}} src={currentImage} alt="" />
          <svg style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${imageDim.x} ${imageDim.y}`} onMouseDown={startDragging} onMouseMove={drag} onMouseUp={stopDragging} >
            {
              elements.map((e:ElementType) => <rect {...e} />)
            }
          </svg>
        </div>
      </Layout.Content>
      <Layout.Sider theme="light">
        <div style={{ display: "flex", flexDirection: "column", gap: 16, paddingLeft: "16px", paddingRight: "16px" }}>
          <div>
            <Tooltip title="Rückgängig">
              <Button 
                disabled={elements.length === 0} 
                onClick={undo} 
                icon={<UndoOutlined />} 
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
            </Tooltip>
            <Tooltip title="Wiederherstellen">
              <Button 
                disabled={undoneElements.length === 0} 
                onClick={redo} 
                icon={<RedoOutlined />} 
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: '-1px' }}
              />
            </Tooltip>
          </div>
          <div style={{ display: "flex" }}>
            <SelectButton className={selectedColor === '#FF0000' ? 'selected' : undefined} onClick={() => setSelectedColor("#FF0000")}><Color color="#FF0000" /></SelectButton>
            <SelectButton className={selectedColor === '#008800' ? 'selected' : undefined} onClick={() => setSelectedColor("#008800")}><Color color="#008800" /></SelectButton>
            <SelectButton className={selectedColor === '#FFFF00' ? 'selected' : undefined} onClick={() => setSelectedColor("#FFFF00")}><Color color="#FFFF00" /></SelectButton>
            <SelectButton className={selectedColor === '#0000FF' ? 'selected' : undefined} onClick={() => setSelectedColor("#0000FF")}><Color color="#0000FF" /></SelectButton>
          </div>
          <div style={{ display: "flex" }}>
            <SelectButton className={selectedShape === 'rect' ? 'selected' : undefined} onClick={() => setSelectedShape("rect")}><div style={{ width: "1.5em", height: '1.5em', border: "1px solid black"}}></div></SelectButton>
            <SelectButton className={selectedShape === 'ellipse' ? 'selected' : undefined} onClick={() => setSelectedShape("ellipse")}><div style={{ width: "1.5em", height: '1.5em', border: "1px solid black", borderRadius: "50%"}}></div></SelectButton>
          </div>
          <div>
            Markieren Sie die relevanten Bereiche auf dem Bild.
          </div>
        </div>
      </Layout.Sider>
    </Layout>
  </Modal>
  </>
}