import { useState, useRef } from 'react';
import { useScrollContext } from './scroll-container';
import { CloseOutlined, DownOutlined, SwapOutlined } from '@ant-design/icons';
import AutoActiveCheckbox from './auto-active-checkbox';
import { Form } from 'antd';
import { useAppDispatch, useAppSelector } from '../store';
import { expandCollapsible } from '../ui-state-slice';

const useCollapsibleExpanded = (key:string):boolean => {
  const expandedCollapsibles = useAppSelector((state) => state.uiState.expandedCollapsibles) || []
  return expandedCollapsibles.indexOf(key) !== -1
}

const useSetCollapsibleExpanded = (key:string): ((expanded:boolean) => void) => {
  const dispatch = useAppDispatch()
  return (expand:boolean) => {
    dispatch(expandCollapsible({ key, expand }))
  }
}

const useCollapsibleReduxState = (key:string): [boolean, (expanded:boolean) => void] => {
  return [useCollapsibleExpanded(key), useSetCollapsibleExpanded(key)]
}

const addToImageFunctions = (node, expanded) => {
  if(node) {
    const rect = node.getBoundingClientRect()
    const visible = expanded && rect.y < (window.visualViewport?.height || 0)
    //console.log('rect', rect, window.visualViewport)
    node.toImage = visible ? undefined : () => null
    node.cloneChildren = visible ? undefined : async (_, clone) => clone
  }
}

export const Collapsible = ({collapsibleKey, title, children}) => {
  const [expanded, setExpanded] = useCollapsibleReduxState(collapsibleKey)
  const { offset } = useScrollContext()
  const ref = useRef<any>()
  const childrenRef = useRef<any>()

  const scrolledOff = offset > ((ref.current?.offsetTop || 0) - 81)

  addToImageFunctions(childrenRef.current, expanded)

  return (
    <>
    { scrolledOff && 
      <div style={{ display: "flex", flexFlow: "row wrap", width: "calc(100% - 30px)", maxWidth: "600px", position: "absolute", top: Math.max(0, (ref.current?.offsetTop || 0) - offset), left: 0, backgroundColor: "white", zIndex: 101, /* boxShadow: Math.max(0, (ref.current?.offsetTop || 0) - offset) === 0 ? "0 10px 10px -15px gray" : undefined  */}}>
        <div style={{ flex: "0 0 25%", textAlign: "right" }}><DownOutlined style={{ fontSize: '200%', marginRight: '0.5em', marginTop: "1em", transform: expanded ? undefined : "rotate(-90deg)", transition: "transform 0.5s" }} onClick={() => setExpanded(!expanded)} /></div>
        <div style={{ flex: 1 }}><h1 style={{ fontFamily: "Tahoma", fontWeight: "normal" }}>{ title }</h1></div>
      </div> }
      <div style={{ display: "flex", flexFlow: "row wrap", minWidth: 0 }} ref={ref}>
        <div style={{ flex: "0 0 25%", textAlign: "right" }}><DownOutlined style={{ fontSize: '200%', marginRight: '0.5em', marginTop: "1em", transform: expanded ? undefined: "rotate(-90deg)", transition: "transform 0.5s" }} onClick={() => setExpanded(!expanded)} /></div>
        <div style={{ flex: 1 }}><h1 style={{ fontFamily: "Tahoma", fontWeight: "normal" }}>{ title }</h1></div>
      </div>
      <div ref={childrenRef} style={{ maxHeight: expanded ? undefined : "0", overflow: "hidden" }}>
        { children }
      </div>
    </>
  )
}

export const SubCollapsible = ({collapsibleKey, title, children, activeName, onDelete, moveIcon }) => {
  const [expanded, setExpanded] = useCollapsibleReduxState(collapsibleKey)
  const { offset } = useScrollContext()
  const ref = useRef<any>()
  const childrenRef = useRef<any>()

  //console.log(ref.current)
  const scrolledOff = (offset > ((ref.current?.offsetTop || 0) - 81 - (ref.current?.offsetHeight || 0))) && offset < ((ref.current?.offsetTop || 0) - 81 + (ref.current?.offsetHeight || 0) + (childrenRef.current?.offsetHeight || 0))

  addToImageFunctions(childrenRef.current, expanded)

  return (
    <>
      { scrolledOff && 
        <div style={{ display: "flex", flexFlow: "row wrap", width: "calc(100% - 30px)", maxWidth: "600px", position: "absolute", top: Math.max(81, (ref.current?.offsetTop || 0) - offset), left: 0, backgroundColor: "white", zIndex: 100 }}>
          <div style={{ flex: "0 0 25%", textAlign: "right" }}><DownOutlined style={{ fontSize: '150%', marginRight: '0.5em', marginTop: "0.4em", transform: expanded ? undefined : "rotate(-90deg)", transition: "transform 0.5s" }} onClick={() => setExpanded(!expanded)} /></div>
          <div style={{ flex: 1, display: "flex", columnGap: 12 }}>
            <h2 style={{ flex: 1, fontFamily: "Tahoma", fontWeight: "normal", margin: 0 }}>{ title }</h2>
            <Form.Item noStyle name={activeName} valuePropName="checked">
              <AutoActiveCheckbox style={{ visibility: activeName ? undefined : 'hidden' }} />
            </Form.Item>
            { moveIcon ?? <SwapOutlined style={{ transform: 'rotate(90deg)', visibility: 'hidden' }} />}
            <CloseOutlined style={{ visibility: onDelete ? undefined : 'hidden' }} onClick={() => onDelete && onDelete()} />
          </div>
        </div>
      }
      <div style={{ display: "flex", flexFlow: "row wrap", minWidth: 0 }} ref={ref}>
        <div style={{ flex: "0 0 25%", textAlign: "right" }}><DownOutlined style={{ fontSize: '150%', marginRight: '0.5em', marginTop: "0.4em", transform: expanded ? undefined : "rotate(-90deg)", transition: "transform 0.5s" }} onClick={() => setExpanded(!expanded)} /></div>
        <div style={{ flex: 1, display: "flex", columnGap: 12 }}>
          <h2 style={{ flex: 1, fontFamily: "Tahoma", fontWeight: "normal", margin: 0 }}>{ title }</h2>
          <Form.Item noStyle name={activeName} valuePropName="checked">
            <AutoActiveCheckbox style={{ visibility: activeName ? undefined : 'hidden' }} />
          </Form.Item>
          { moveIcon ?? <SwapOutlined style={{ transform: 'rotate(90deg)', visibility: 'hidden' }} />}
          <CloseOutlined style={{ visibility: onDelete ? undefined : 'hidden' }} onClick={() => onDelete && onDelete()} />
        </div>
      </div>
      <div style={{ maxHeight: expanded ? undefined : "0", overflow: "hidden" }} ref={childrenRef}>
        { children }
      </div>
    </>
  )
}

export const SubSubCollapsible = ({title, children}) => {
  const [expanded, setExpanded] = useState(true)
  const childrenRef = useRef<any>()

  addToImageFunctions(childrenRef.current, expanded)

  return (
    <>
      <div style={{ display: "flex", flexFlow: "row wrap", minWidth: 0, margin: 0 }}>
        <div style={{ flex: "0 0 25%", textAlign: "right" }}><DownOutlined style={{ fontSize: '100%', marginRight: '0.5em', marginTop: "1.5em", transform: expanded ? undefined :  "rotate(-90deg)", transition: "transform 0.5s" }} onClick={() => setExpanded(!expanded)} /></div>
        <div style={{ flex: 1 }}><h3 style={{ fontFamily: "Tahoma", fontWeight: "normal" }}>{ title }</h3></div>
      </div>
      <div style={{ maxHeight: expanded ? undefined : "0", overflow: "hidden" }} ref={childrenRef}>
        { children }
      </div>
    </>
  )
}