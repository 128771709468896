import { useRef, useCallback, useMemo, useEffect, useState } from "react"
import { Checkbox, Form, Input, Modal, Select } from "antd"
import { ProfileType } from "../types/profile"
import { useCreateProfileVariantMutation, useGetProfilesByConsultantIdentsLightQuery } from "../api/profile-api"
import { LoadingOutlined } from "@ant-design/icons"

function NewVariantForm({ consultantIdent, onComplete }: { consultantIdent: string | null, onComplete: (ident: string | null) => void }) {
  const formRef = useRef<any>()
  const { data: variants }: { data: ProfileType[] } = useGetProfilesByConsultantIdentsLightQuery([consultantIdent], { skip: !consultantIdent}) as { data: ProfileType[] }
  const [createProfileVariant] = useCreateProfileVariantMutation()
  const [creating, setCreating] = useState<boolean>(false)

  const doCreateVariant = useCallback(async () => {
    setCreating(true)
    const params = formRef.current?.getFieldsValue(true)
    const { data: newVariant } : { data: ProfileType } = (await createProfileVariant(params)) as { data: ProfileType }
    onComplete(`${newVariant.consultant?.ident}-${newVariant.variant}`)
    setCreating(false)
  }, [onComplete, formRef, createProfileVariant, setCreating])

  const baseOptions = useMemo(() => variants?.map((v, idx) => ({value: `${v.consultant?.ident}-${v.variant}`, label: idx === 0 ? 'Hauptprofil' : v.description})), [variants])

  useEffect(() => {
    if(consultantIdent) {
      const values = { baseIdent: `${consultantIdent}-0`, description: '' }
      formRef.current?.setFieldsValue(values)
    }
  }, [formRef, baseOptions, consultantIdent])

  return (
    <Modal 
      title="Profilvariante erstellen" 
      open={!!consultantIdent} 
      onOk={doCreateVariant}
      okButtonProps={{icon: creating ? <LoadingOutlined /> : undefined, disabled: creating}}
      onCancel={() => !creating && onComplete(null)}
      cancelButtonProps={{ disabled: creating }}
      >
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          initialValues={{ base: `${consultantIdent}-0` }}
        >
          <Form.Item name={["description"]} label="Beschreibung">
            <Input />
          </Form.Item>
          <Form.Item name={["baseIdent"]} label="Basis">
            <Select options={baseOptions} virtual={false} />
          </Form.Item>
          <Form.Item name={["keepKeywords"]} label="Keywords übernehmen">
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default NewVariantForm