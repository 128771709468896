import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const DropArea = styled.div`
  width: 280px;
  height: 140px;
  border: 2px dashed grey;
  text-align: center;
  padding-top: 4em;
`

const ImageContainer = styled.div`
  width: 280px;
  height: 140px;
  overflow: hidden;

  img {
    width: 280px;
    height: 140px;
    object-fit: contain;
    /* cursor-events: none; */
  }
`

const LogoImageSelector = ({ value, onChange }:any) => {
  const loadFile = useCallback((file:File) => {
    const fileReader:FileReader = new FileReader();
    fileReader.addEventListener('load', (evt) => {
      onChange && onChange(evt.target?.result)
    });
    fileReader.readAsDataURL(file)
  }, [onChange])

  const dropHandler = useCallback((evt) => {
    console.log("File(s) dropped");
  
    // Prevent default behavior (Prevent file from being opened)
    evt.preventDefault();
  
    if (evt.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...evt.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file:File = item.getAsFile();
          loadFile(file)
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...evt.dataTransfer.files].forEach((file:File, i) => {
        loadFile(file)
      });
    }
  }, [loadFile])

  return (
    <div style={{ display: "flex", flexDirection: "column" , alignItems: "center"}}>
      { !value && <DropArea onDrop={dropHandler} onDragOver={evt => evt.preventDefault()}>Drag & Drop Bild hier</DropArea> }
      { value && 
        <>
        <ImageContainer>
          <img src={value} alt="Logo" />
        </ImageContainer>
        <Button style={{ marginTop: "1em" }} icon={<CloseOutlined />} onClick={() => onChange(null)}/>
        </> }
    </div>
  )
}

export default LogoImageSelector