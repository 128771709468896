import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithPrepareHeaders } from "./util";

const BASE_URL = '/api/profile/consultant'

const baseQuery = fetchBaseQueryWithPrepareHeaders({
  baseUrl: `${BASE_URL}/`,
})

export const consultantApi = createApi({
  reducerPath: 'consultantApi',
  baseQuery,
  tagTypes: ['Consultant'],
  endpoints: (builder:any) => ({
    getConsultants: builder.query({
      query: () => ``,
      providesTags: ['Consultant']
    }),
  })
})

export const { 
  useGetConsultantsQuery,
} = consultantApi