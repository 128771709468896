import { useRef, useCallback, useMemo, useEffect } from 'react'
import { useGetTenantQuery, useUpdateTenantMutation } from "../../api/tenant-api"
import FormWithPreview from "../form-with-preview"
import { Collapsible } from '../collapsibles'
import FormItem from '../profile-form/form-item'
import { ColorPicker, Input } from 'antd'
import { useGetConsultantsQuery } from '../../api/consultant-api'
import { useGetProfileByIdentQuery } from '../../api/profile-api'
import LogoImageSelector from '../logo-image-selector'
import { useBreadcrumbContext } from '../breadcrumb'
import { BankOutlined, HomeOutlined } from '@ant-design/icons'

const ColorPickerWrapper = ({ value, onChange}:any) => {
  console.log("Setting value", value)
  const onChangeInternal = useCallback((colorObj) => {
    const pad = s => `00${s}`.substring(s.length)
    const hexString = pad((colorObj.metaColor?.r || 0).toString(16)) + pad((colorObj.metaColor?.g || 0).toString(16)) + pad((colorObj.metaColor?.b || 0).toString(16))
    console.log("hexString", hexString, colorObj)
    onChange && onChange(hexString)
  }, [onChange])
  return <ColorPicker disabledAlpha value={`${value}`} onChange={onChangeInternal} />
}


function Company() {
  const formRef = useRef()
  const { data: tenants, isLoading: isLoadingTenant } = useGetTenantQuery({})
  const tenant = useMemo(() => tenants ? tenants[0] : undefined, [tenants])
  const { data: consultants, isLoading: isLoadingConsultant } = useGetConsultantsQuery({})
  const firstConsultant = useMemo(() => consultants ? consultants[0] : undefined, [consultants])
  const { data: profile, isLoading: isLoadingProfile } = useGetProfileByIdentQuery(firstConsultant ? `${firstConsultant.ident}-0` : undefined)
  const [updateTenant, { isLoading: saving }] = useUpdateTenantMutation()
  const { setItems: setBreadcrumbItems } = useBreadcrumbContext()
  const isLoading = useMemo(() => isLoadingTenant || isLoadingConsultant || isLoadingProfile, [isLoadingTenant, isLoadingConsultant, isLoadingProfile])
  
  useEffect(() => {
    setBreadcrumbItems([
      {
        href: '',
        title: <HomeOutlined />,
      },
      {
        href: '',
        title: <><BankOutlined /><span>Firmendaten</span></>,

      }
    ])

    return () => setBreadcrumbItems([])
  }, [setBreadcrumbItems])

  const onSave = useCallback((values) => {
    updateTenant({ item: values })    
  }, [updateTenant])

  const buildRenderData = useCallback((values) => {
    console.log("Building render data", profile, values)
    return {...(profile as any), consultant: {...(profile || {} as any).consultant, tenant: values} }
  }, [profile])

  return (
    <FormWithPreview ref={formRef} saving={saving} onSave={values => onSave(values)} loading={isLoading} values={tenant} buildRenderData={buildRenderData}>
      { ({ form }) => (
        <>
          <Collapsible collapsibleKey="company/design" title="Design">
            <FormItem name={["logo"]} label="Firmenlogo" inputElem={<LogoImageSelector />} />
            <FormItem name={["primaryColor"]} label="Primärfarbe" inputElem={<ColorPickerWrapper />} />
            <FormItem name={["secondaryColor"]} label="Sekundärfarbe" inputElem={<ColorPickerWrapper />} />
          </Collapsible>
          <Collapsible collapsibleKey="company/footer" title="Fußzeile">
            <FormItem name={["documentFooter"]} label="Fußzeilentext" inputElem={<Input.TextArea rows={4} />} />
          </Collapsible>
      </>
      ) }
    </FormWithPreview>
  )
}

export default Company