import { useRef, useState, useCallback, useEffect } from 'react'
import { Button, Form, Input, Select, Slider } from "antd"
import { useGetProfileByIdentQuery, useUpdateProfileByIdentMutation } from "../../api/profile-api";

import { styled } from 'styled-components';
import FormItem from '../profile-form/form-item';
import DatestringPicker from '../datestring-picker';
import ReorderContainer from '../reorder-container';
import { useParams } from 'react-router-dom'
import KeywordsInput from '../keywords-input';
import ExcludedKeywordsInput from '../excluded-keywords-input';
import ProfileImageSelector from '../profile-image-selector';
import { Collapsible, SubCollapsible, SubSubCollapsible } from '../collapsibles';
import FormWithPreview from '../form-with-preview';
import FormValueText from '../form-value-text';
import { HomeOutlined, PicRightOutlined, UserOutlined } from '@ant-design/icons';
import { useBreadcrumbContext } from '../breadcrumb';
import { ProfileType } from '../../types/profile';

const AddButtonRow = ({onAdd, label}) => {
  return (
    <FormItem label="Neu" inputElem={
      <Button type="dashed" onClick={onAdd} block>
        { label }
      </Button>
    }/>
  )
}

const ExperienceLevelSelectorBox = styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid black;
  margin-right: 0.25em;
  cursor: pointer;

  &.hover {
    background-color: gray;
  }

  &.active {
    background-color: black;
  }

`

interface ExperienceLevelSelectorProps {
  value?: number;
  onChange?: (value: number) => void;
}

const ExperienceLevelSelector: React.FC<ExperienceLevelSelectorProps> = ({value, onChange}) => {
  const [hoverIdx, setHoverIdx] = useState(0)

  return (
    <div style={{ marginTop: '4px'}}>
      { new Array(5).fill(null).map((_, idx) => <ExperienceLevelSelectorBox key={idx} className={[(value || 0) > idx ? 'active' : undefined, hoverIdx >= idx ? 'hover' : undefined].join(" ")} onClick={() => onChange && onChange(idx + 1)} onMouseOver={() => setHoverIdx(idx)} onMouseOut={() => setHoverIdx(0)} />)}
    </div>
  )
}

interface LanguageLevelSelectorProps {
  value?: number
  onChange?: (value:number) => void
}

const LanguageLevelSelector: React.FC<LanguageLevelSelectorProps> = ({value, onChange}) => {
  return (
    <Select value={value} onChange={onChange} style={{ width: "12em"}} options={[
      {
        value: 1,
        label: "Grundkenntnisse"
      },
      {
        value: 2,
        label: "Fortgeschritten"
      },
      {
        value: 3,
        label: "Fließend"
      },
      {
        value: 4,
        label: "Verhandlungssicher"
      },
      {
        value: 5,
        label: "Muttersprache"
      }
    ]} />
  )
}

const ProfileDescriptionInput = ({ value, onChange, form }:any) => {
  const variant = Form.useWatch(["variant"], { form, preserve: true })
  return (
    <Input value={!variant ? 'Hauptprofil' : value} onChange={onChange} disabled={!variant} />
  )
}

const InputCurrencyWrapperStyled = styled.div`
  input {
    text-align: right;
  }
`

const InputCurrency = ({ value, onChange }:any) => {
  return (
    <InputCurrencyWrapperStyled>
      <Input value={value} onChange={onChange} addonAfter="EUR" />
    </InputCurrencyWrapperStyled>
  )
}

const AvailabilitySlider = ({ value, onChange}:any) => {
  return (<div style={{ width: "100%", display: "flex", gap: 8 }}>
    <Slider value={value} onChange={onChange} style={{ flex: 1 }} defaultValue={100} min={0} max={100} step={5} />
    <div style={{ paddingTop: "0.3em"}}>{value || 0}%</div>
  </div>)
}

const BodyLeasingSelector = ({ value, onChange}:any) => {
  return (
    <Select value={value} onChange={onChange} options={[
      {
        value: true,
        label: "möglich"
      },
      {
        value: false,
        label: "nicht erwünscht"
      }
    ]} />
  )
}

function Profile() {
  const { profileId } = useParams()

  const formRef = useRef<any>()

  const { data: profile }: { data:ProfileType | undefined } = useGetProfileByIdentQuery(profileId) as { data: ProfileType | undefined }
  const [updateProfile, { isLoading: saving }] = useUpdateProfileByIdentMutation()

  const { setItems: setBreadcrumbItems } = useBreadcrumbContext()

  //console.log("profile id", profileId, profile, `${(profile as any)?.consultant?.ident}-${(profile as any)?.variant}`)
  
  const onSave = useCallback((values) => {
    updateProfile({ id: profileId, item: values })    
  }, [updateProfile, profileId])

  useEffect(() => {
    setBreadcrumbItems([
      {
        href: '',
        title: <HomeOutlined />,
      },
      {
        href: '',
        title: <><UserOutlined /><span>{ profile?.consultant ? `${profile.consultant.firstName} ${profile.consultant.lastName}` : 'Consultant' }</span></>,
      },
      {
        title: <><PicRightOutlined /><span>{ profile?.variant === 0 ? "Hauptprofil" : profile?.description }</span></>
      }
    ])

    return () => setBreadcrumbItems([])
  }, [setBreadcrumbItems, profile])

  return (
    <FormWithPreview 
        ref={formRef}
        saving={saving} 
        onSave={values => onSave(values)} 
        values={profile}
        loading={`${(profile as any)?.consultant?.ident}-${(profile as any)?.variant}` !== profileId}
    >
      { ({ form }) => (
        <>
          <Collapsible collapsibleKey="profile/description" title={<>Profilvariante <FormValueText form={form} name={["description"]} default="Hauptprofil" /></>}>
            <FormItem name={["description"]} label="Bezeichnung" inputElem={<ProfileDescriptionInput form={form} />} />
          </Collapsible>

          <Collapsible collapsibleKey="profile/buzzwordBingo" title="Buzzword-Bingo">
            <FormItem label="Keywords" name={["keywords"]} inputElem={<KeywordsInput form={form} />} activeName={["highlightKeywords"]} />
            <FormItem label="Ausgeschl. Keywords" name={["excludedKeywords"]} inputElem={<ExcludedKeywordsInput />} />
          </Collapsible>
          
          <Collapsible collapsibleKey="profile/consultantData" title="Consultant-Daten">
            <FormItem label="Vorname" name={["consultant", "firstName"]} />
            <FormItem label="Namename" name={["consultant", "lastName"]} />
            <FormItem label="Bild" name={["image"]} inputElem={<ProfileImageSelector nameImageTransform="imageTransform" />} />
            <FormItem label="Abschluss" name={["degree"]} />
            <FormItem label="Rolle" name={["role"]} />
          </Collapsible>

          <Collapsible collapsibleKey="profile/contact" title="Kontakt">
            <FormItem label="Adresszeile 1" name={["contact", "contactLine1"]} />
            <FormItem label="Adresszeile 2" name={["contact", "contactLine2"]} />
            <FormItem label="Adresszeile 3" name={["contact", "contactLine3"]} />
            <FormItem label="Adresszeile 4" name={["contact", "contactLine4"]} />
            <FormItem label="Adresszeile 5" name={["contact", "contactLine5"]} />
            <FormItem label="Telefon" name={["contact", "phoneNumber"]} activeName={["contact", "phoneNumberActive"]} />
            <FormItem label="Mobil" name={["contact", "mobileNumber"]} activeName={["contact", "mobileNumberActive"]} />
            <FormItem label="E-Mail" name={["contact", "email"]} activeName={["contact", "emailActive"]} />
            <FormItem label="Website" name={["contact", "website"]}  activeName={["contact", "websiteActive"]}/>
            { false  && <FormItem label="LinkedIn" name={["contact", "linkedIn"]}  activeName={["contact", "linkedInActive"]}/> }
            { false && <FormItem label="Xing" name={["contact", "xing"]} activeName={["contact", "xingActive"]} /> }
          </Collapsible>

          <Collapsible collapsibleKey="profile/conditions" title="Konditionen">
            <FormItem label="Satz remote" name={["conditions", "rateOffSite"]} activeName={["conditions", "rateOffSiteActive"]} inputElem={<InputCurrency />} />
            <FormItem label="Satz on-site" name={["conditions", "rateOnSite"]} activeName={["conditions", "rateOnSiteActive"]} inputElem={<InputCurrency />} />
            <FormItem label="On-site Standort" name={["conditions", "siteLocation"]} activeName={["conditions", "siteLocationActive"]} />
            <FormItem label="Auslastung" name={["conditions", "availabilityPercent"]} activeName={["conditions", "availabilityPercentActive"]} inputElem={<AvailabilitySlider />} />
            <FormItem label="Verfügbarkeit on-site" name={["conditions", "availabilityOnSite"]} activeName={["conditions", "availabilityOnSiteActive"]} inputElem={<AvailabilitySlider />} />
            <FormItem label="Verfügbarkeit ab" name={["conditions", "availabilityDate"]} activeName={["conditions", "availabilityDateActive"]} inputElem={<DatestringPicker placeholder="Startdatum" />} />
            <FormItem label="ANÜ" name={["conditions", "allowBodyLeasing"]} activeName={["conditions", "allowBodyLeasingActive"]} inputElem={<BodyLeasingSelector />}/>
          </Collapsible>

          <Collapsible collapsibleKey="profile/languageItems" title="Sprachkenntnisse">
            <Form.List name={["languageItems"]}>
              {(subFields, subOpt) => (
                <>
                  <ReorderContainer type="languageItem" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                    {(subField, idx, moveIcon) => {
                      return <FormItem key={subField.key} name={[subField.name, 'text']} activeName={[subField.name, 'active']} label={ `Sprachkenntnis ${idx + 1}` } onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon} inputElem={
                        <>
                          <Form.Item noStyle name={[subField.name, 'text']}>
                            <Input style={{ flex: 1 }} />
                          </Form.Item>
                          <Form.Item noStyle name={[subField.name, 'level']}>
                            <LanguageLevelSelector />
                          </Form.Item>
                        </>
                      } />
                    }}
                  </ReorderContainer>
                  <AddButtonRow onAdd={() => subOpt.add()} label="+ Sprachkenntis-Eintrag hinzufügen" />
                </>
              )}
            </Form.List>
          </Collapsible>

          <Collapsible collapsibleKey="profile/shortProfileItems" title={ `Kurzprofil` }>
            <Form.List name={["shortProfileItems"]}>
              {(subFields, subOpt) => (
                <>
                  <ReorderContainer type="shortProfileItems" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                    {(subField, idx, moveIcon) => {
                      return <FormItem key={subField.key} name={[subField.name, 'text']} activeName={[subField.name, 'active']} label={ `Kurzprofil ${idx + 1}` } onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon} />
                    }}
                  </ReorderContainer>
                  <AddButtonRow onAdd={() => subOpt.add()} label="+ Kurzprofil-Eintrag hinzufügen" />
                </>
              )}
            </Form.List>
          </Collapsible>

          <Collapsible collapsibleKey="profile/educationItems" title="Ausbildung und Zertifizierungen">
            <Form.List name={["educationItems"]}>
              {(subFields, subOpt) => (
                  <>
                    <ReorderContainer type="educationItems" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                      {(subField, idx, moveIcon) => (
                        <FormItem key={subField.key} name={[subField.name, 'text']} activeName={[subField.name, 'active']} label={ `Ausbildung ${idx + 1}` } onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon}/>
                      )}
                    </ReorderContainer>
                    <AddButtonRow onAdd={() => subOpt.add()} label="+ Ausbildungs-Eintrag hinzufügen" />
                  </>
                )}
            </Form.List>
          </Collapsible>

          <Collapsible collapsibleKey="profile/skillGroups" title="Skills">
            <Form.List name={["skillGroups"]}>
              {(subFields, subOpt) => (
                <>
                  <ReorderContainer type="skillGroups" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                    {(subField, idx, moveIcon) => (
                      <SubCollapsible collapsibleKey={`profile/skillGroups/${idx}/skills`} title={<FormValueText form={form} name={["skillGroups", subField.name, "title"]} default={`Skillgruppe ${idx + 1}`} />} activeName={[subField.name, 'active']} onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon} >
                        <FormItem key={subField.key} name={[subField.name, 'title']} label={ `Gruppe ${idx + 1}` }/>
                        <Form.List name={[subField.name, "skills"]}>
                          {(subSubFields, subSubOpt) => (
                            <>
                              <ReorderContainer 
                                  type={`skills-${subField.name}`} 
                                  elems={subSubFields} 
                                  calcKey={e => `${e.key}`}
                                  onReorder={subSubOpt.move}
                                  gap={4}
                                >
                                {(subSubField, _, moveIcon) => (
                                  <FormItem name={[subSubField.name]} key={subSubField.key} label="Skill" moveIcon={moveIcon} onDelete={() => subSubOpt.remove(subSubField.name)} activeName={[subSubField.name, "active"]} inputElem={
                                    <>
                                      <Form.Item noStyle name={[subSubField.name, 'text']}>
                                        <Input style={{ flex: 1 }} />
                                      </Form.Item>
                                      <Form.Item noStyle name={[subSubField.name, 'experienceLevel']}>
                                        <ExperienceLevelSelector />
                                      </Form.Item>
                                    </>
                                  } />
                                )}
                              </ReorderContainer>
                              <AddButtonRow onAdd={() => subSubOpt.add()} label="+ Skill hinzufügen" />
                            </>
                          )}
                        </Form.List>
                      </SubCollapsible>
                      )}
                  </ReorderContainer>
                  <AddButtonRow onAdd={() => subOpt.add()} label="+ Skill-Gruppe hinzufügen" />
                </>
              )}
            </Form.List>
          </Collapsible>

          <Collapsible collapsibleKey="profile/projects" title="Projektreferenzen">
            <Form.List name={["projects"]}>
              {(subFields, subOpt) => (
                <>
                  <ReorderContainer type="projects" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                    {(subField, idx, moveIcon) => (
                      <SubCollapsible key={subField.key} collapsibleKey={`profile/projects/${idx}/project`} title={<FormValueText form={form} name={["projects", subField.name, "title"]} default={`Project ${idx + 1}`} />} activeName={[subField.name, 'active']} onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon}>
                        <FormItem name={[subField.name, 'title']} label={ `Titel ${idx + 1}` }  />
                        <FormItem name={[subField.name, 'startDate']} label={ `Laufzeit` } inputElem={<>
                            <Form.Item name={[subField.name, 'startDate']}><DatestringPicker placeholder="Startdatum" picker="month" /></Form.Item>
                            <div style={{ marginTop: "0.2em" }}>bis</div>
                            <Form.Item name={[subField.name, 'endDate']}><DatestringPicker placeholder="Enddatum" picker="month" /></Form.Item>
                          </>
                        } />
                        <FormItem name={[subField.name, 'description']} label={ `Beschreibung` } inputElem={ <Input.TextArea rows={4} /> } />
                        <SubSubCollapsible title={`Skills`}>
                          <Form.List name={[subField.name, "skills"]}>
                            {(subSubFields, subSubOpt) => (
                              <>
                              <ReorderContainer 
                                  type={`projectSkills-${subField.name}`} 
                                  elems={subSubFields} 
                                  calcKey={e => `${e.key}`}
                                  onReorder={subSubOpt.move}
                                  gap={4}
                                >
                                {(subSubField, _, subMoveIcon) => (
                                    <FormItem name={[subSubField.name, 'text']} activeName={[subSubField.name, 'active']} label={ `Skill` } onDelete={() => subSubOpt.remove(subSubField.name)} moveIcon={subMoveIcon} />
                                )}
                                </ReorderContainer>
                                <AddButtonRow onAdd={() => subSubOpt.add()} label="+ Skill-Eintrag hinzufügen" />
                              </>
                            )}
                          </Form.List>
                        </SubSubCollapsible>
                      </SubCollapsible>
                    )}
                  </ReorderContainer>
                  <AddButtonRow onAdd={() => subOpt.add()} label="+ Projekt-Eintrag hinzufügen" />
                </>
                )}
            </Form.List>
          </Collapsible>

          <Collapsible collapsibleKey="profile/testimonialItems" title="Kundenfeedback">
            <Form.List name={["testimonialItems"]}>
              {(subFields, subOpt) => (
                  <>
                    <ReorderContainer type="testimonialItems" elems={subFields} calcKey={e => `${e.key}`} onReorder={subOpt.move}>
                      {(subField, idx, moveIcon) => (
                        <>
                          <FormItem key={subField.key} name={[subField.name, 'text']} activeName={[subField.name, 'active']} label={ `Feedback ${idx + 1}` } onDelete={() => subOpt.remove(subField.name)} moveIcon={moveIcon} inputElem={ <Input.TextArea rows={4} /> }/>
                          <FormItem key={subField.key} name={[subField.name, 'person']} label={ `Feedbackgeber` }/>
                        </>
                      )}
                    </ReorderContainer>
                    <AddButtonRow onAdd={() => subOpt.add()} label="+ Feedback-Eintrag hinzufügen" />
                  </>
                )}
            </Form.List>
          </Collapsible>
        </>
      )}
    </FormWithPreview>
  )
}

export default Profile