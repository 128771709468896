import { Fragment, useEffect, useState, useRef } from 'react'
import { Project } from "../types/profile"
import BuzzwordBingoInput from "./buzzword-bingo-input"
import { Form, FormInstance, Popover } from 'antd'

interface KeywordsInputPropsType {
  value?: string[],
  onChange?: (v:string[]) => void,
  getValues?: () => any,
  form: FormInstance<any>
}

const occurrences = (string:string, subString:string, allowOverlapping:boolean, exclusions?:string[]):number => {
  for(let exclusion of exclusions || []) {
    string = string.replaceAll(exclusion, '')
  }

  string += "";
  subString += "";
  if (subString.length <= 0) return (string.length + 1);

  var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

  while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
          ++n;
          pos += step;
      } else break;
  }
  return n;
}

const countProjectSkillOccurences = (project:Project, v:string, exclusions?:string[]):number => {
  return (project.skills && project.skills.filter(s => s && s.active).map(s => ((s.text && occurrences(s.text, v, false, exclusions)) || 0)).reduce((a:number, b:number) => a + b, 0)) || 0
}

interface EvaluationResultType {
  found: {
    shortProfileItems: number,
    educationItems: number,
    skillGroups: number,
    projects: number
  }
}

const PopoverContent = ({ evaluationResult }: {evaluationResult: EvaluationResultType}) => {
  return (
    <div>
      <p><u>Keyword gefunden</u>
        <ul>
          <li>{evaluationResult.found.shortProfileItems}x im Kurzprofil</li>
          <li>{evaluationResult.found.educationItems}x in Ausbildung und Zertifizierungen</li>
          <li>{evaluationResult.found.skillGroups}x in den Skills</li>
          <li>{evaluationResult.found.projects}x in Projekten</li>
        </ul>
      </p>
    </div>
  )
}

const useDebounce = (cb: () => (void | (() => {})), deps:any, delay: number = 1000): void => {
  const timeoutRef = useRef<any>()

  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      const res = cb()
      return () => {
        clearTimeout(timeoutRef.current)
        res && res()
      }
    }, delay)
  }, [
    /* eslint-disable react-hooks/exhaustive-deps */
    ...deps, 
    /* eslint-enable */
    timeoutRef, cb, delay])
}

function KeywordsInput({ value, onChange, getValues, form }: KeywordsInputPropsType) {
  const [evaluationResult, setEvaluationResult] = useState<any>([])

  const shortProfileItems = Form.useWatch(["shortProfileItems"], { form, preserve: true })
  const educationItems = Form.useWatch(["educationItems"], { form, preserve: true })
  const skillGroups = Form.useWatch(["skillGroups"], { form, preserve: true })
  const projects = Form.useWatch(["projects"], { form, preserve: true })
  const excludedKeywords = Form.useWatch(["excludedKeywords"], { form, preserve: true })
  
  useDebounce(() => {
    const newEvaluationResult = value?.map((k:string) => k.trim()).map(v => {
      const e: EvaluationResultType = v.length < 3 ? {
        found: { shortProfileItems: 0, educationItems: 0, skillGroups: 0, projects: 0 }
      } : { 
        found: {
          shortProfileItems: shortProfileItems?.filter(e => e && e.active).map(ei => (ei.text && occurrences(ei.text, v, false, excludedKeywords)) || 0).reduce((a:number, b:number) => a + b, 0) || 0,
          educationItems: educationItems?.filter(e => e && e.active).map(ei => (ei.text && occurrences(ei.text, v, false, excludedKeywords)) || 0).reduce((a:number, b:number) => a + b, 0) || 0,
          skillGroups: (skillGroups?.filter(sg => sg && sg.active).map(sg => (sg.title && occurrences(sg.title, v, false, excludedKeywords)) || 0).reduce((a:number, b:number) => a + b, 0) || 0) + (skillGroups?.filter(sg => sg && sg.active).map(sg => sg.skills?.filter(s => s && s.active).map(s => (s.text && occurrences(s.text, v, false, excludedKeywords)) || 0).reduce((a: number, b:number) => a + b, 0)).reduce((a:number, b:number) => a + b, 0) || 0),
          projects: projects?.filter(p => p && p.active).map(p => ((p.title && occurrences(p.title, v, false, excludedKeywords)) || 0) + ((p.description && occurrences(p.description, v, false, excludedKeywords)) || 0) + countProjectSkillOccurences(p, v, excludedKeywords)).reduce((a:number, b:number) => a + b, 0) || 0,
        }
      }
      
      return e
    })
    setEvaluationResult(newEvaluationResult)
  }, [value, setEvaluationResult, shortProfileItems, educationItems, skillGroups, projects, excludedKeywords])

  return (
    <BuzzwordBingoInput value={value} onChange={onChange} annotationLines={ evaluationResult?.map((e, idx) => <Fragment key={idx}><Popover content={<PopoverContent evaluationResult={e} />} title="Auswertung">{e.found.shortProfileItems + e.found.educationItems + e.found.skillGroups + e.found.projects}</Popover><br/></Fragment>) } />
  )
}

export default KeywordsInput