import { useEffect } from 'react'
import { Checkbox } from "antd"


function AutoActiveCheckbox({ checked, onChange, ...rest }: any) {
  
  useEffect(() => {
    if(checked === undefined && onChange) {
      onChange(true)
    }
  }, [checked, onChange])

  return (
    <Checkbox checked={checked} onChange={onChange} {...rest} />
  )
}

export default AutoActiveCheckbox