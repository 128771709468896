import { useCallback, useMemo, ReactNode } from 'react'
import TextArea from "antd/es/input/TextArea"

interface BuzzwordBingoInputPropsType {
  value?: string[],
  onChange?: (v:string[]) => void,
  annotationLines?: ReactNode[]
}

function BuzzwordBingoInput({ value, onChange, annotationLines }: BuzzwordBingoInputPropsType) {

  const onChangeInternal = useCallback(evt => {
    const value = evt.target.value
    let splitted:string[]
    let words = value.split("\n")
    if(words.length > 1) {
      splitted = words
    } else {
      words = value.split(/\s/g)
      if(words.length > 3) {
        splitted = words
      } else {
        splitted = [value]
      }
    }
    splitted = splitted.map((s:string) => s.substring(0, 31))
    onChange && onChange(splitted)
  }, [onChange])

  const internalValue = useMemo(() => value?.join("\n"), [value])

  const height = useMemo(() => Math.max(((value?.length || 0) + 1) * 22, 20) + "px", [value])

  return (
    <div style={{ display: "flex", flexDirection: "row"}}>
      <div style={{ height, backgroundColor: "#CCCCCC", padding: "4px", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", textAlign: "right" }}>
        { annotationLines }
      </div>
      <TextArea value={internalValue} onChange={onChangeInternal} rows={(value?.length || 0) + 1} style={{ height, width: "300px", flex: 1, resize: 'none', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, fontFamily: "Courier new" }} />
    </div>
  )
}

export default BuzzwordBingoInput