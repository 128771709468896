import { useMemo, useCallback, useState, ReactNode, useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Space, Button, Select, Tag, Tooltip, Popover, CollapseProps, Collapse } from 'antd'
import { styled } from 'styled-components'
import { useGetProfileByIdentQuery, useGetProfilesByConsultantIdentsLightQuery, useUpdateProfileByIdentMutation } from '../../api/profile-api'
import { ProfileType } from '../../types/profile'
import { ChangeType, diffProjects } from '../../util/project-diff'
import { HomeOutlined, ProfileOutlined, RedoOutlined, SaveOutlined, SwapOutlined, UndoOutlined } from '@ant-design/icons'
import { ChangeDescription, useSaveReminderContext } from '../save-reminder'
import LoadingOverlay from '../loading-overlay'
import { useBreadcrumbContext } from '../breadcrumb'

interface SubFilterType {
  key: string,
  label: string,
}

interface FilterType {
  label: string,
  key: string,
  subFilter: SubFilterType[]
}

const allFilters:FilterType[] = [
  {
    label: "Filter",
    key: "",
    subFilter: [
      {
        key: "consultantData",
        label: "Consultant-Daten"
      }
    ]
  },
  {
    label: "Kontaktfilter",
    key: "contact",
    subFilter: [
      {
        key: "content",
        label: "Inhalt"
      },
      {
        key: "contentActive",
        label: "Aktiv"
      }
    ]
  },
  {
    label: "Konditionenfilter",
    key: "conditions",
    subFilter: [
      {
        key: "content",
        label: "Inhalt"
      },
      {
        key: "contentActive",
        label: "Aktiv"
      }
    ]
  },
  {
    label: "Projektfilter",
    key: "project",
    subFilter: [
      {
        key: "existing",
        label: "Vorhanden"
      },
      {
        key: "active",
        label: "Aktiv"
      },
      {
        key: "title",
        label: "Titel"
      },
      {
        key: "startDate",
        label: "Startdatum"
      },
      {
        key: "endDate",
        label: "Enddatum"
      },
      {
        key: "description",
        label: "Beschreibung"
      }
    ]
  }
]

const TableRow = styled.div`
  display: table-row;
  height: 3em;

  &:nth-child(even) {
    background-color: #EEEEEE;
  }
`

const TableCell = styled.div`
  display: table-cell;
  padding: 0.5em;

  &:nth-child(2) {
    width: 30%;
  }

  &:nth-child(4), &:nth-child(8) {
    width: 32%;
  }

  &:nth-child(1), &:nth-child(5), &:nth-child(6) {
    width: 2%;
  }
`

const MarkTableCell = styled<any>(TableCell)`
  background-color: ${p => p.changed ? "red" : "green"};
`

const DisablableCheckableTagStyle = styled<any>(Tag.CheckableTag)`
  ${p => p.disabled ? `
    opacity: 0.5;
    pointer-events: none;
  ` : ''}
`

const DisablableCheckableTag = ({children, ...rest}:any) => {
  return (
    <DisablableCheckableTagStyle {...rest}>
      {children}
    </DisablableCheckableTagStyle>
  )
}

const FilterRow = ({ label, filterKey, subFilter, diff, filters, setFilters }:any) => {
  return (
    <div>
      <span style={{ marginRight: 8 }}>{ label }:</span>
      <Space size={[0, 8]} wrap>
        <Tag.CheckableTag
          key={filterKey}
          checked={subFilter.filter(sf => filters.indexOf(`${filterKey}::${sf.key}::`) !== -1).length === subFilter.length}
          onChange={(checked) => setFilters([...filters.filter(f => !f.startsWith(`${filterKey}::`)), ...(checked ? subFilter.map(sf => `${filterKey}::${sf.key}::`) : [])])}
        >
          Alle ({ diff.filter((d:ChangeType) => {
            return d.key[0] === filterKey
          }).length})
        </Tag.CheckableTag>
        {
          subFilter.map(sf => {
            const elemCount = diff.filter((d:ChangeType) => [...d.key, ""].join("::").startsWith(`${filterKey}::${sf.key}::`)).length
            return (
              <DisablableCheckableTag
                key={`${filterKey}::${sf.key}`}
                checked={elemCount > 0 && filters.indexOf(`${filterKey}::${sf.key}::`) !== -1}
                onChange={(checked) => setFilters([...filters.filter(f => f !== `${filterKey}::${sf.key}::`), ...(checked ? [`${filterKey}::${sf.key}::`] : [])])}
                disabled={ elemCount === 0}
              >
                { sf.label } ({ elemCount })
              </DisablableCheckableTag>
            )
          })
        }
        
      </Space>
    </div>
  )
}

const ChangedDotStyle = styled.div`
  width: 0.5em;
  height: 0.5em;
  background-color: blue;
  border-radius: 0.25em;
  cursor: pointer;
`

const ChangedDot = ({ change }: { change: ChangeType}) => {
  return (
    <Popover content={change.textB} title="Änderung">
      <ChangedDotStyle />
    </Popover>
  )
}

interface UndoRedoElement {
  profileAOverride: ProfileType | undefined
  profileBOverride: ProfileType | undefined
  actionDescription: ReactNode
}

const filterCollapseItems: (filters:ReactNode) => CollapseProps['items'] = (filters) => [
  {
    key: '1',
    label: 'Filter',
    children: filters,
  }
]

function CompareProfileVariants() {
  const { consultantId } = useParams()
  const [filters, setFilters] = useState<string[]>(allFilters.map(af => af.subFilter.map(sf => `${af.key}::${sf.key}::`)).flatMap(e => e))
  const [allDiffsOnly, setAllDiffsOnly] = useState<string>("all")
  const [searchParams, setSearchParams] = useSearchParams()
  const [profileAOverride, setProfileAOverride] = useState<ProfileType | undefined>()
  const [profileBOverride, setProfileBOverride] = useState<ProfileType | undefined>()
  const [profileAOverrideForUndo, setProfileAOverrideForUndo] = useState<ProfileType | undefined>()
  const [profileBOverrideForUndo, setProfileBOverrideForUndo] = useState<ProfileType | undefined>()
  const [undoStack, setUndoStack] = useState<UndoRedoElement[]>([])
  const [redoStack, setRedoStack] = useState<UndoRedoElement[]>([])
  const [updateProfile, { isLoading: saving }] = useUpdateProfileByIdentMutation()
  const saveCallbacksRef = useRef<(() => Promise<void>)[]>([async () => {}, async () => {}])
  const { setItems: setBreadcrumbItems } = useBreadcrumbContext()
  
  const { setChanges, saveReminderTrigger } = useSaveReminderContext()

  const profileIdA = searchParams.get("a")
  const profileIdB = searchParams.get("b")

  const { data: variants, isFetching: loadingAll }: { data?: ProfileType[], isFetching: boolean } = useGetProfilesByConsultantIdentsLightQuery([consultantId], { skip: !consultantId }) as { data?: ProfileType[], isFetching: boolean }
  const { data: origProfileVariantA, isFetching: loadingProfileVariantA } : { data?:ProfileType, isFetching: boolean} = useGetProfileByIdentQuery(profileIdA, { skip: !profileIdA }) as { data?: ProfileType, isFetching: boolean }
  const { data: origProfileVariantB, isFetching: loadingProfileVariantB } : { data?:ProfileType, isFetching: boolean} = useGetProfileByIdentQuery(profileIdB, { skip: !profileIdB }) as { data?: ProfileType, isFetching: boolean }

  useEffect(() => {
    setBreadcrumbItems([
      {
        href: '',
        title: <HomeOutlined />,
      },
      {
        href: '',
        title: <><ProfileOutlined /><span>{ (variants && variants.length > 0) ? `${variants[0].consultant?.firstName} ${variants[0].consultant?.lastName}` : 'Consultant' }</span></>,
      },
      {
        title: <><SwapOutlined /><span>Varianten abgleichen</span></>
      }
    ])

    return () => setBreadcrumbItems([])
  }, [setBreadcrumbItems, variants])

  const loading = loadingAll || loadingProfileVariantA || loadingProfileVariantB

  const profileVariantA = useMemo(() => profileAOverride || origProfileVariantA, [origProfileVariantA, profileAOverride])
  const profileVariantB = useMemo(() => profileBOverride || origProfileVariantB, [origProfileVariantB, profileBOverride])

  useEffect(() => {
    setProfileAOverride(undefined)
    setProfileBOverride(undefined)
    setUndoStack([])
    setRedoStack([])
  }, [profileIdA, profileIdB, setProfileAOverride, setProfileBOverride, setUndoStack, setRedoStack])

  useEffect(() => {
    return () => setChanges([])
  }, [setChanges])

  const diff:ChangeType[] = useMemo(() => {
    let result:ChangeType[] = []
    
    if(profileVariantA && profileVariantB) {
      result = diffProjects(profileVariantA, profileVariantB)
    }

    return result
  }, [profileVariantA, profileVariantB])

  const calcChanged = useCallback((override:ProfileType | undefined, profileId:string | null):ChangeType[] => {
    let result:ChangeType[] = []
    const origProfile = variants?.find(p => `${p.consultant?.ident}-${p.variant}` === profileId)
    if(override && origProfile) {
      result = diffProjects(override, origProfile)
    }
    return result
  }, [variants])

  const changedA:ChangeType[] = useMemo(() => calcChanged(profileAOverride, profileIdA), [calcChanged, profileAOverride, profileIdA])
  const changedB:ChangeType[] = useMemo(() => calcChanged(profileBOverride, profileIdB), [calcChanged, profileBOverride, profileIdB])

  console.log("diff", diff)

  const selectOptions = useMemo(() => (variants || []).map((v, idx) => ({value: `${v.consultant?.ident}-${v.variant}`, label: idx === 0 ? 'Hauptprofil' : v.description})), [variants])

  const selectOption = useCallback((ab:string, profileIdent:string) => {
    saveReminderTrigger(() => {
      const params = {} as any
      if(profileIdA) {
        params.a = profileIdA
      }
      if(profileIdB) {
        params.b = profileIdB
      }
      params[ab] = profileIdent
      setSearchParams(params)
    })
  }, [setSearchParams, profileIdA, profileIdB, saveReminderTrigger])

  const addUndo = useCallback((actionDescription:ReactNode) => {
    const newUndoStack:UndoRedoElement[] = [...undoStack, {profileAOverride, profileBOverride, actionDescription}]
    if(newUndoStack.length > 20) {
      newUndoStack.shift()
    }
    setUndoStack(newUndoStack)
    setRedoStack([])
  }, [profileAOverride, profileBOverride, undoStack, setUndoStack, setRedoStack])

  const setChangesWrapper = useCallback((profileAOverride:(ProfileType | undefined), profileBOverride:(ProfileType | undefined)) => {
    const newChanges:ChangeDescription[] = []
    if(profileAOverride) {
      newChanges.push({
        description: profileAOverride?.description || "Hauptprofil",
        saveCallback: () => saveCallbacksRef.current[0]()
      })
    }
    if(profileBOverride) {
      newChanges.push({
        description: profileBOverride?.description || "Hauptprofil",
        saveCallback: () => saveCallbacksRef.current[1]()
      })
    }
    setChanges(newChanges)
  }, [setChanges, saveCallbacksRef])

  const setProfileAOverrideWithUndo = useCallback((profileAOverride:ProfileType, actionDescription:ReactNode):void => {
    addUndo(actionDescription)
    setProfileAOverride(profileAOverride)
    setProfileAOverrideForUndo(profileAOverride)
    setChangesWrapper(profileAOverride, profileBOverride)
  }, [addUndo, setProfileAOverride, setProfileAOverrideForUndo, setChangesWrapper, profileBOverride])

  const setProfileBOverrideWithUndo = useCallback((profileBOverride:ProfileType, actionDescription:ReactNode):void => {
    addUndo(actionDescription)
    setProfileBOverride(profileBOverride)
    setProfileBOverrideForUndo(profileBOverride)
    setChangesWrapper(profileAOverride, profileBOverride)
  }, [addUndo, setProfileBOverride, setProfileBOverrideForUndo, setChangesWrapper, profileAOverride])

  const undo = useCallback(() => {
    const newUndoStack = [...undoStack]
    const item = newUndoStack.pop() || { profileAOverride: undefined, profileBOverride: undefined, actionDescription: "unknown" }
    const newRedoStack:UndoRedoElement[] = [...redoStack, {profileAOverride: profileAOverrideForUndo, profileBOverride: profileBOverrideForUndo, actionDescription: item.actionDescription}]
    setUndoStack(newUndoStack)
    setRedoStack(newRedoStack)
    setProfileAOverride(item.profileAOverride)
    setProfileAOverrideForUndo(item.profileAOverride)
    setProfileBOverride(item.profileBOverride)
    setProfileBOverrideForUndo(item.profileBOverride)
    setChangesWrapper(item.profileAOverride, item.profileBOverride)
  }, [undoStack, setUndoStack, redoStack, setRedoStack, profileAOverrideForUndo, setProfileAOverride, setProfileAOverrideForUndo, profileBOverrideForUndo, setProfileBOverride, setProfileBOverrideForUndo, setChangesWrapper])

  const redo = useCallback(() => {
    const newRedoStack = [...redoStack]
    const item = newRedoStack.pop() || { profileAOverride: undefined, profileBOverride: undefined, actionDescription: "unknown" }
    const newUndoStack:UndoRedoElement[] = [...undoStack, {profileAOverride: profileAOverrideForUndo, profileBOverride: profileBOverrideForUndo, actionDescription: item.actionDescription}]
    setRedoStack(newRedoStack)
    setUndoStack(newUndoStack)
    setProfileAOverride(item.profileAOverride)
    setProfileAOverrideForUndo(item.profileAOverride)
    setProfileBOverride(item.profileBOverride)
    setProfileBOverrideForUndo(item.profileBOverride)
    setChangesWrapper(item.profileAOverride, item.profileBOverride)
  }, [undoStack, setUndoStack, redoStack, setRedoStack, profileAOverrideForUndo, setProfileAOverride, setProfileAOverrideForUndo, profileBOverrideForUndo, setProfileBOverride, setProfileBOverrideForUndo, setChangesWrapper])

  const performAction = useCallback((action:() => ProfileType, setter: (profile:ProfileType, actionDescription:ReactNode) => void, actionDescription:ReactNode) => {
    const newProfile = action()
    setter(newProfile, actionDescription)
    //console.log("New profile", newProfile)
  }, [])

  const saveProfileA = useCallback(async () => {
    await updateProfile({id: profileIdA, item: profileAOverride})
    setProfileAOverride(undefined)
    setChangesWrapper(undefined, profileBOverride)
  }, [updateProfile, profileIdA, profileAOverride, setProfileAOverride, profileBOverride, setChangesWrapper])

  const saveProfileB = useCallback(async () => {
    await updateProfile({id: profileIdB, item: profileBOverride})
    setProfileBOverride(undefined)
    setChangesWrapper(profileAOverride, undefined)
  }, [updateProfile, profileIdB, profileBOverride, setProfileBOverride, profileAOverride, setChangesWrapper])

  saveCallbacksRef.current = [saveProfileA, saveProfileB]

  console.log("Select options", selectOptions)

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: 16, position: "relative" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
        <div>
          <Tooltip title={undoStack.length ? <>Rückgängig: {undoStack[undoStack.length - 1].actionDescription}</> : undefined}>
            <Button icon={<UndoOutlined />} disabled={undoStack.length === 0} onClick={undo} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
          </Tooltip>
          <Tooltip title={redoStack.length ? <>Wiederherstellen: {redoStack[redoStack.length - 1].actionDescription}</> : undefined}>
            <Button icon={<RedoOutlined />} disabled={redoStack.length === 0} onClick={redo} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: "-1px" }} />
            </Tooltip>
        </div>
        <Select style={{ width: "15em" }} value={allDiffsOnly} onChange={setAllDiffsOnly} options={[
          {
            label: "Alles",
            value: "all"
          },
          {
            label: "Nur Unterschiede",
            value: "diffs-only"
          }
        ]} />
      </div>
      <Collapse items={filterCollapseItems(<>
      {
        allFilters.map(af => (<FilterRow label={af.label} filterKey={af.key} diff={diff} filters={filters} setFilters={setFilters} subFilter={af.subFilter} />))
      }</>)} />
      <div style={{ flex: 1, overflow: "auto", paddingBottom: "3em" }}>
        <div style={{ display: "table", width: "100%" }}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Space>
                <Select options={selectOptions} style={{ width: "20em" }} value={profileIdA} onChange={evt => selectOption("a", evt)} virtual={false} />
                <Button icon={<SaveOutlined />} type="primary" disabled={!profileAOverride} onClick={saveProfileA}>Speichern</Button>
              </Space>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Space>
                <Select options={selectOptions} style={{ width: "20em" }} value={profileIdB} onChange={evt => selectOption("b", evt)} virtual={false} />
                <Button icon={<SaveOutlined />} type="primary" disabled={!profileBOverride} onClick={saveProfileB}>Speichern</Button>
              </Space>
            </TableCell>
          </TableRow>
          {
            diff.filter(d => allDiffsOnly === "diffs-only" ? d.changed : true).filter(d => filters.find(e => [...d.key, ""].join("::").startsWith(e))).map(c => <TableRow>
              <MarkTableCell changed={c.changed}></MarkTableCell>
              <TableCell>{ c.description }</TableCell>
              <TableCell>{ changedA?.filter(v => v.uniqueKey === c.uniqueKey).filter(c => c.changed).map(c => <ChangedDot change={c} />)}</TableCell>
              <TableCell>
                { c.textA }
              </TableCell>
              <TableCell>
                { c.changed && 
                <Tooltip title={ profileVariantA && c.descriptionActionA }>
                  <Button size='small' icon={ c.iconA } onClick={() => performAction(c.actionA, setProfileAOverrideWithUndo, c.descriptionActionA)} />
                </Tooltip> }
              </TableCell>
              <TableCell>
                { c.changed &&
                <Tooltip title={ profileVariantB && c.descriptionActionB }>
                  <Button size='small' icon={ c.iconB } onClick={() => performAction(c.actionB, setProfileBOverrideWithUndo, c.descriptionActionB)} />
                </Tooltip> }
              </TableCell>
              <TableCell>{ changedB?.filter(v => v.uniqueKey === c.uniqueKey).filter(c => c.changed).map(c => <ChangedDot change={c} />)}</TableCell>
              <TableCell>
                { c.textB }
              </TableCell>
            </TableRow>
            )
          }
        </div>
      </div>
      <LoadingOverlay isLoading={saving || loading} loadingLabel={`Daten werden ${saving ? "gespeichert" : "aktualisiert"}`}/>
    </div>
  )
}

export default CompareProfileVariants