import { styled } from 'styled-components'

const ImageContainer = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 70px;
  overflow: hidden;

  img {
    width: 140px;
    cursor-events: none;
  }
`

function ImageTransformContainer({imageUrl, transform, ...rest}:any) {
  return (
    <ImageContainer {...rest}>
      <img 
        style={{ transform }}
        src={imageUrl} 
        alt="Bild" />
    </ImageContainer>
  )
}

export default ImageTransformContainer