import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithPrepareHeaders } from "./util";

const BASE_URL = '/api/feedback'

const baseQuery = fetchBaseQueryWithPrepareHeaders({
  baseUrl: `${BASE_URL}/`,
})

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery,
  tagTypes: ['Feedback'],
  endpoints: (builder:any) => ({
    /*
    getOidcConfig: builder.query({
      query: () => `auth/oidc-config`,
      providesTags: ['OIDC-Config']
    }),
    */
    sendFeedback: builder.mutation({
      query: (item) => ({
        url: 'feedback',
        method: 'POST',
        body: item
      }),
    }),
  })
})

export const { 
  useSendFeedbackMutation
} = feedbackApi