import { configureStore } from "@reduxjs/toolkit";
import uiStateReducer from './ui-state-slice';
import userReducer from './user-slice';
import { profileApi } from "./api/profile-api";
import { consultantApi } from "./api/consultant-api";
import { tenantApi } from "./api/tenant-api";
import { authApi } from './api/auth-api';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { feedbackApi } from "./api/feedback-api";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('uiState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('uiState', serializedState);
  } catch {
    // ignore write errors
  }
}

const persistedState = loadState()

export const store = configureStore({
  reducer: {
    uiState: uiStateReducer,
    user: userReducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [consultantApi.reducerPath]: consultantApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(profileApi.middleware)
      .concat(consultantApi.middleware)
      .concat(tenantApi.middleware)
      .concat(feedbackApi.middleware)
    ,
  preloadedState: {
    uiState: persistedState
  }
})

store.subscribe(() => {
  saveState(store.getState().uiState)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector