import { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react'
import domtoimage from './feedback/dom-to-image'

const ScrollContext = createContext({ offset: 0 })

export const useScrollContext = () => useContext(ScrollContext)

function ScrollContainer({ style, children, ...rest}:any) {
  const scrollContainerRef = useRef<any>()
  const [offset, setOffset] = useState<number>(0)

  useEffect(() => {
    const scrollListener = evt => {
      //console.log("Scrolled", evt, evt.target.scrollTop)
      setOffset(evt.target.scrollTop)
    }
    const node = scrollContainerRef.current
    node?.addEventListener("scroll", scrollListener)

    return () => node?.removeEventListener("scroll", scrollListener)

  }, [scrollContainerRef, setOffset])

  const cloneChildren = useCallback(async (original, clone) => {
    const imgUri = await domtoimage.toPng(scrollContainerRef.current.childNodes[0], {})
    //console.log("To image", img)
    const imageNode = await new Promise((resolve, reject) => {
      var image = new Image();
      image.onload = function () {
        resolve(image);
      };
      image.onerror = reject;
      image.src = imgUri;
      image.setAttribute("style", `position: relative; top: -${scrollContainerRef.current.scrollTop}px`)
    })
    clone.appendChild(imageNode)
    return clone
  }, [])

  if(scrollContainerRef.current) {
    scrollContainerRef.current.cloneChildren = cloneChildren
    //scrollContainerRef.current.toImage = toImage
  }

  return (
    <div style={{ overflow: "auto", ...style }} {...rest} ref={scrollContainerRef}>
      <div>
        <ScrollContext.Provider value={{ offset }}>
          {children}
        </ScrollContext.Provider>
      </div>
    </div>
  )
}

export default ScrollContainer