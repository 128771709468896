import { CameraOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback, useState } from "react";
import SnapshotPanel from "./snapshot-panel";

interface SnapshotFieldPropsType {
  value?: string,
  onChange?: (newValue:string) => void,
  takeScreenshot: () => Promise<string>,
  baseZIndex: number,
}

const awaitTimeout = (delay:number):Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

function SnapshotField({ value, onChange, takeScreenshot, baseZIndex }:SnapshotFieldPropsType) {
  const [takingScreenshot, setTakingScreenshot] = useState<boolean>(false)
  const [rawSnapshot, setRawSnapshot] = useState<string | undefined>()
  const [editedSnapshot, setEditedSnapshot] = useState<string | undefined>()

  const takeAndProcessScreenshot = useCallback(async () => {
    setTakingScreenshot(true)
    await awaitTimeout(250)
    try {
      const image = await takeScreenshot()
      setRawSnapshot(image)
    } catch(e) {
      console.error("Caught exception in create screenshot", e)
    }
    setTakingScreenshot(false)
  }, [takeScreenshot, setRawSnapshot])

  const onFinishScreenshotEdit = useCallback((img) => {
    setEditedSnapshot(img)
    setRawSnapshot(undefined)
  }, [setRawSnapshot, setEditedSnapshot])

  return (
    <>
      { editedSnapshot && <img src={editedSnapshot} style={{ width: '100%' }} alt="Snapshot" /> }
      { editedSnapshot && <Button danger style={{ width: "100%", marginBottom: "1em" }} onClick={() => setEditedSnapshot(undefined)} icon={<DeleteOutlined />}>Screenshot entfernen</Button> }
      <Button style={{ width: "100%" }} onClick={takeAndProcessScreenshot} disabled={takingScreenshot} icon={takingScreenshot ? <LoadingOutlined /> : <CameraOutlined />}>Screenshot aufnehmen</Button>
      <SnapshotPanel currentImage={rawSnapshot} baseZIndex={baseZIndex + 10} onFinish={onFinishScreenshotEdit} />
    </>
  )
}

export default SnapshotField