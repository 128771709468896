import { useCallback } from 'react';
import { DatePicker } from "antd";
import dayjs from 'dayjs'

interface DatestringPickerPropsType {
  placeholder?: string,
  value?: string,
  onChange?: (value:string) => void,
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year" | undefined,
}

function DatestringPicker({ placeholder, value, onChange, picker = "date" }: DatestringPickerPropsType) {
  const onChangeInternal = useCallback(v => {
    const s = v ? v.date(1).format('YYYY-MM-DD') : null
    onChange && onChange(s)
  }, [onChange])

  return (
    <DatePicker picker={picker} placeholder={placeholder} onChange={onChangeInternal} value={value ? dayjs(value, "YYYY-MM-DD") : undefined} />
  )
}

export default DatestringPicker