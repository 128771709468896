import { SaveOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { createContext, useState, useCallback, useContext } from 'react'

export interface ChangeDescription {
  description: string,
  saveCallback: () => Promise<void>
}

interface SaveReminderContextValueType {
  setChanges: (changes:ChangeDescription[]) => void,
  saveReminderTrigger: (cb: ()=>void) => void
}

const SaveReminderContext = createContext<SaveReminderContextValueType>({ setChanges: () => {}, saveReminderTrigger: () => {} })

export const useSaveReminderContext: () => SaveReminderContextValueType = () => useContext(SaveReminderContext)

function SaveReminder({children}) {

  const [changes, setChanges] = useState<ChangeDescription[]>([])
  const [showWarning, setShowWarning] = useState<boolean>(false)

  const trigger = useCallback((cb:() => void) => {
    if(changes.length > 0) {
      setShowWarning(true)
    } else {
      cb()
    }
  }, [changes, setShowWarning])

  return (<SaveReminderContext.Provider value={{ setChanges, saveReminderTrigger: trigger }}>
    <Modal title="Änderungen Speichern?" open={showWarning} onCancel={() => setShowWarning(false)} footer={[
          <Button key="cancel" onClick={() => setShowWarning(false)}>
            Abbrechen
          </Button>,
          <Button key="continue" danger type="primary" onClick={() => setShowWarning(false)}>
            Weiter ohne Speichern
          </Button>,
          <Button
            icon={<SaveOutlined />}
            key="saveAll"
            type="primary"
            loading={false}
            onClick={() => {}}
          >
            { changes.length > 1 ? "Alle speichern" : "Speichern" }
          </Button>,
        ]}>
      <div>
        <p>
        { changes.length > 1 ? "Die folgenden Profile wurden geändert:" : "Das folgende Profil wurde geändert:" }
        </p>
        <ul style={{ lineHeight: 2 }}>
          { changes.map(c => <li key={c.description}>{ c.description } { changes.length > 1 && <Button icon={<SaveOutlined />} key="c.description" type="primary" onClick={c.saveCallback} size="small" /> }</li>)}
        </ul>
        <p>
          Wollen Sie die Änderungen speichern bevor Sie fortfahren? Wenn Sie ohne zu speichern fortfahren, gehen die Änderungen verloren.
        </p>
      </div>
    </Modal>
    {children}
  </SaveReminderContext.Provider>)
}

export default SaveReminder